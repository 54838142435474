import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Snackbar,
    Alert
} from '@mui/material';
import { readString } from 'react-papaparse';
import { bulkUpdateAcquShortlistedDomain } from '../api';

export default function UpdateShortlistedDomain({ open, handleClose, onSuccess }) {
    const [excelUrl, setExcelUrl] = useState('');
    const [urlError, setUrlError] = useState('');
    const [updateError, setUpdateError] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // Clear all states
    const resetStates = () => {
        setExcelUrl('');
        setUrlError('');
        setUpdateError('');
        setDisabled(true);
        setLoading(false);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        resetStates();
        handleClose();
    };

    const validateGoogleSheetsUrl = (url) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[^\/]+\/pub\?output=csv$/;
        return regex.test(url);
    };

    const validateHeaders = (dataArray, requiredHeaders) => {
        if (dataArray.length === 0) return false;
        const firstRow = dataArray[0];
        return requiredHeaders.every(header => Object.hasOwnProperty.call(firstRow, header));
    };

    const validateNumericFields = (data) => {
        return data.every(row => {
            const bids = parseInt(row.bids);
            const price = parseInt(row.price);
            return !isNaN(bids) && !isNaN(price);
        });
    };

    const handleExcelUrlChange = (e) => {
        const url = e.target.value;
        setExcelUrl(url);
        if (validateGoogleSheetsUrl(url)) {
            setUrlError('');
            setDisabled(false);
        } else if (url === '') {
            setUrlError('');
            setDisabled(true);
        } else {
            setUrlError('URL must be of the format: https://docs.google.com/spreadsheets/d/e/<YOUR-DOCUMENT-LINK>/pub?output=csv');
            setDisabled(true);
        }
    };

    const parseCSV = (csvData) => {
        return new Promise((resolve, reject) => {
            readString(csvData, {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.toLowerCase().replace(/\s+/g, ''),
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const handleUpdate = async () => {
        if (!excelUrl || !validateGoogleSheetsUrl(excelUrl)) return;

        setLoading(true);
        setUpdateError('');

        try {
            const csvResponse = await fetch(excelUrl);
            const csvData = await csvResponse.text();

            const parsedData = await parseCSV(csvData);
            const requiredHeaders = ['domain', 'bids', 'price'];
            
            if (!validateHeaders(parsedData, requiredHeaders)) {
                throw new Error('The CSV file must contain "Domain", "Bids", and "Price" headers.');
            }

            if (!validateNumericFields(parsedData)) {
                throw new Error('Bids and Price must be valid numbers.');
            }

            const processedData = parsedData.map(row => ({
                ...row,
                bids: parseInt(row.bids),
                price: parseInt(row.price)
            }));

            const response = await bulkUpdateAcquShortlistedDomain(processedData);
            
            if (response.status >= 200 && response.status < 300) {
                // Show success message
                setSnackbar({
                    open: true,
                    message: 'Domains updated successfully!',
                    severity: 'success'
                });

                // Call parent's success callback
                if (onSuccess) {
                    onSuccess();
                }
                
                setTimeout(() => {
                    handleDialogClose();
                }, 1000);
            } else {
                throw new Error('Failed to update domains');
            }

        } catch (error) {
            console.error('Error updating domains:', error);
            setUpdateError(error.message || 'Failed to update domains. Please try again.');
            setSnackbar({
                open: true,
                message: error.message || 'Failed to update domains',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                        boxShadow: 'none',
                        border: '1px solid #e0e0e0',
                    }
                }}
            >
                <DialogTitle 
                    sx={{ 
                        py: 1.5, 
                        px: 2,
                        backgroundColor: '#f5f5f5',
                        fontSize: '0.9rem',
                        borderBottom: '1px solid #e0e0e0'
                    }}
                >
                    Update Shortlisted Domains
                </DialogTitle>
                <DialogContent sx={{ p: 2, backgroundColor: '#fff' }}>
                        <Stack spacing={2} paddingTop={2}>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Excel Sheet URL"
                                variant="outlined"
                                value={excelUrl}
                                onChange={handleExcelUrlChange}
                                error={!!urlError}
                                helperText={urlError || "Paste your published Google Sheets URL (entire doc. + .csv)"}
                                disabled={loading}
                                sx={{ 
                                    '& .MuiInputBase-root': {
                                        height: '36px',
                                        fontSize: '0.7rem',
                                        backgroundColor: '#fff',
                                        fontStyle: 'italic',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5'
                                        }
                                    },
                                    '& .MuiFormHelperText-root': {
                                        fontSize: '0.7rem',
                                        marginLeft: 0,
                                        fontStyle: 'italic'
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.7rem',
                                        fontStyle: 'italic'
                                    }
                                }}
                            />
                            {updateError && (
                                <Typography 
                                    color="error" 
                                    variant="caption" 
                                    sx={{ 
                                        pl: 0.5,
                                        fontSize: '0.7rem',
                                        fontStyle: 'italic'
                                    }}
                                >
                                    {updateError}
                                </Typography>
                            )}
                        </Stack>
                </DialogContent>
                <DialogActions 
                    sx={{ 
                        py: 1.5, 
                        px: 2,
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: '#f5f5f5'
                    }}
                >
                    <Button 
                        onClick={handleDialogClose}
                        variant="outlined"
                        disabled={loading}
                        size="small"
                    >
                        Cancel
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button 
                        variant="contained"
                        disabled={disabled || loading}
                        onClick={handleUpdate}
                        size="small"
                    >
                        {loading ? 'Updating...' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={2000} 
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    severity={snackbar.severity}
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    sx={{ 
                        width: '100%',
                        fontSize: '0.7rem',
                        '& .MuiAlert-message': {
                            padding: '5px 0'
                        }
                    }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}