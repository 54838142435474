import { Box, Button, Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { OldDomainsList } from "./OldDomains";
import { getDomainLiveData } from "./api";

export default function DomainLive() 
{

    const [data,setData]=useState({old:[],today:[],hot:[]})

    useEffect(()=>{getDomainLiveData().then((res)=>{setData(res.data); console.log(res.data)}).catch((err)=>{console.log(err)})},[])

    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Processing Live
    </Typography>
   
    <Box sx={{flexGrow:1}}/>
            </Stack>
            <Grid alignItems='start' justifyContent='space-around' container width='100%'>
<Grid xs={3.5} item >
<OldDomainsList data={data.old} title="Old Domains" />
</Grid>
<Grid xs={3.5} item>
<OldDomainsList data={data.hot} title="Hotpicks"  />
</Grid>
<Grid xs={3.5} item>
<OldDomainsList data={data.today} title="Today"  />
</Grid>
    </Grid>
            </Stack>
            </ThemeProvider>
    )
}