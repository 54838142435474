import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography, Tooltip, Checkbox, Tabs, Tab, OutlinedInput, ListItemText, Chip } from "@mui/material";
import { theme } from '../Theme';
import { FileDownloadOutlined } from '@mui/icons-material';
import React, { useEffect, useMemo, useState, useCallback } from "react";
import UploadCSVDialog from "../UploadCSVDialog";
import UploadCSVDialogLtdDomains from "../Common/UploadCSVDialogLtdDomains";
import { isAdmin, canAddLtd } from '../msalService';
import { Add, Edit, PanToolAltOutlined, Sort as SortIcon, Person, Groups } from "@mui/icons-material";
import { allocateDomainToProcessor, allocateDomainToTeam, editLtdDomains, getAllLtdDomains, getAllLtdDomainsWithPagination, getAllOwnedDomain, getAllTags, uploadDomains, uploadLtdDomains, uploadOwnedDomains, allocateDomainToTeams, allocateDomainToProcessors, getAllPortfolioDomains } from "../api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from '../UserProvider';
import FilterListIcon from '@mui/icons-material/FilterList';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import AllocationDialog from '../Common/AllocationDialog';
import OverwriteDialog from '../Common/OverwriteDialog';
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function LtdStudioClosed() {

  const [domains, setDomains] = useState([]);
  const [editDomains, setEditDomains] = useState([]);
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);

  let [idds, setIdds] = React.useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const { user, updateUser } = useUser();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50, });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');

  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  const [ltdTab, setLtdTab] = useState('ALL');

  const handleTabChange = (event, newValue) => {
    setLtdTab(newValue);
  };

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {
      // Add ltdState and platform to filters
      ltdState: {
        operator: 'equals',
        type: 'text',
        value: 'closed'
      },
      ...((ltdTab !== 'ALL' && ltdTab !== 'ALD' && ltdTab !== 'PD') && {
        platform: {
          operator: 'equals',
          type: 'text',
          value: ltdTab.toLowerCase()
        }
      })
    });

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    let portfolio = 'ltd';
    if (ltdTab === 'ALD' || ltdTab === 'PD') {
      portfolio = ltdTab.toLowerCase();
    }
  
    try {
      getAllPortfolioDomains({
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
        portfolio: portfolio
      })
        .then(response => {
          if (response && response.data) {
            setData(response.data.content);
            setTotalRowCount(response.data.totalElements);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalRowCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchData:', error);
      setLoading(false);
    }
  }, [columnFilters, sorting, pagination, globalSearch, ltdTab]);

  // Add handlers for filter and sort dialogs
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch, ltdTab]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClose1 = () => {
    setOpenDialog1(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
    setExistingDomains([]);
    setSelectedExistingDomains([]);
    setOpenExistingDomainsDialog(false);
  };

  useEffect(() => {
    console.log(editDomains);
    if (editDomains && editDomains.length > 0)
      editLtdDomains(editDomains)
        .then((res) => {
          console.log(res);
          setUpsuc(true);
          setEditDomains([]);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [editDomains]);

  const handleSelectAll = (event) => {
    setSelectedExistingDomains(event.target.checked ? existingDomains : []);
  };

  const handleExistingDomainSelect = (event) => {
    const { value } = event.target;
    setSelectedExistingDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
  };

  const handleCloseExistingDomainsDialog = () => {
    setOpenExistingDomainsDialog(false);
    setSelectedExistingDomains([]);
    setExistingDomains([]);
    setDomains([]);
    if (idds.length > 0) {
      setOpenAllocate(true);
    }
  };

  const handleUploadDomains = async (domains, overwrite = false) => {
    try {
      const res = await uploadLtdDomains(domains, overwrite);
      const newIds = res.data.savedDomainIds;
      setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));

      if (res.data.existingDomains?.length > 0) {
        setExistingDomains(res.data.existingDomains);
        setOpenExistingDomainsDialog(true);
      } else {
        setOpenAllocate(true);
        setUpsuc(true);
        setDomains([]);
        handleCloseExistingDomainsDialog();
      }
      fetchData();
    } catch (err) {
      console.log(err);
      setDomains([]);
      setUpfail(true);
    }
  };

  useEffect(() => {
    if (domains && domains.length > 0) {
      handleUploadDomains(domains);
    }
  }, [domains]);

  useEffect(() => {
    fetchData();
    getAllTags()
      .then((res) => {
        const arr = res.data.map((ar) => ar.tag);
        console.log(arr);
        setTags(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleExportData = useCallback((selectedRows) => {
    try {
      const exportColumns = [
        'domain', 'type', 'lander', 'host', 'wby', 'aby', 'reg', 'regx',
        'tld_count', 'tld_count_dev', 'sg', 'cpc', 'bids', 'currentPrice',
        'EST', 'GDV', 'ESW', 'GDW', 'spr500', 'sprGDV', 'clientOffer', 'reco'
      ];

      const header = exportColumns.join(',') + '\n';
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          const value = row.original[col];
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      const csv = header + csvContent;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `ltd_domains_closed_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setUpsuc(true);
    } catch (error) {
      console.error('Export error:', error);
      setUpfail(true);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "dateOfUpload",
        header: "adate",
        size: 60,
        accessorFn: (row) => {
          const date = row.dateOfUpload ? new Date(row.dateOfUpload) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      },
      {
        accessorKey: "edate",
        header: "edate",
        size: 60,
        accessorFn: (row) => {
          const date = row.edate ? new Date(row.edate) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      },
      {
        accessorKey: "domain",
        header: "domain",
        size: 120,
        accessorFn: (row) => row.domain || '-'
      },
      {
        accessorKey: "type",
        header: "type",
        size: 60,
        accessorFn: (row) => row.type || '-'
      },
      {
        accessorKey: "lander",
        header: "lander",
        size: 60,
        accessorFn: (row) => row.lander || '-'
      },
      {
        accessorKey: "host",
        header: "host",
        size: 50,
        accessorFn: (row) => row.host || '-'
      },
      {
        accessorKey: "wby",
        header: "wby",
        size: 50,
        accessorFn: (row) => {
          const wby = row.wby;
          if (wby?.length >= 4) {
            return new Date().getFullYear() - parseInt(wby.substring(0, 4), 10);
          }
          return '-';
        }
      },
      {
        accessorKey: "aby",
        header: "aby",
        size: 40,
        accessorFn: (row) => {
          const aby = row.aby;
          if (aby?.length >= 4) {
            return new Date().getFullYear() - parseInt(aby.substring(0, 4), 10);
          }
          return '-';
        }
      },
      {
        accessorKey: "reg",
        header: "reg",
        size: 40,
        accessorFn: (row) => row.reg !== null && row.reg !== -1 ? row.reg : '-'
      },
      {
        accessorKey: "regx",
        header: "Regx",
        size: 40,
        accessorFn: (row) => row.regx !== null && row.regx !== -1 ? row.regx : '-'
      },
      {
        accessorKey: 'tld_count',
        header: 'tldc',
        size: 50,
        accessorFn: (row) => row?.tld_count || '-'
      },
      {
        accessorKey: 'tld_count_dev',
        header: 'tldd',
        size: 50,
        accessorFn: (row) => row?.tld_count_dev || '-'
      },
      {
        accessorKey: "sg",
        header: "sg",
        size: 50,
        enableSorting: true,
        accessorFn: (row) => row.sg !== null && row.sg !== -1 ? row.sg : '-'
      },
      {
        accessorKey: "cpc",
        header: "cpc",
        size: 50,
        accessorFn: (row) => row.cpc !== null && row.cpc !== -1 ? row.cpc.toFixed(1) : '-'
      },
      {
        accessorKey: "bids",
        header: "bids",
        size: 50,
        accessorFn: (row) => row.bids !== null && row.bids !== 0 ? Math.round(row.bids) : '-'
      },
      {
        accessorKey: "currentPrice",
        header: "price",
        size: 60,
        accessorFn: (row) => row.currentPrice !== null && row.currentPrice !== 0 ? Math.round(row.currentPrice) : '-'
      },
      {
        accessorKey: "EST",
        header: "est",
        size: 60,
        accessorFn: (row) => row.EST !== null && row.EST !== -1 ? Math.round(row.EST / 100) * 100 : '-'
      },
      {
        accessorKey: "GDV",
        header: "gdv",
        size: 50,
        accessorFn: (row) => row.GDV !== null && row.GDV !== -1 ? Math.round(row.GDV / 100) * 100 : '-'
      },
      {
        accessorKey: "ESW",
        header: "esw",
        size: 50,
        accessorFn: (row) => row.ESW !== null && row.ESW !== 0 ? Math.round(row.ESW / 100) * 100 : '-'
      },
      {
        accessorKey: "GDW",
        header: "gdw",
        size: 50,
        accessorFn: (row) => row.GDW !== null && row.GDW !== 0 ? Math.round(row.GDW / 100) * 100 : '-'
      },
      {
        accessorKey: "spr500",
        header: "S500",
        size: 50,
        accessorFn: (row) => row.spr500,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value !== null ? `${Math.round(value * 100)}%` : '-';
        }
      },
      {
        accessorKey: "sprGDV",
        header: "sgdv",
        size: 50,
        accessorFn: (row) => row.sprGDV,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value !== null ? `${Math.round(value * 100)}%` : '-';
        }
      },
      {
        accessorKey: "clientOffer",
        header: "offer",
        size: 50,
        accessorFn: (row) => row.clientOffer !== null && row.clientOffer !== 0 ? Math.round(row.clientOffer) : '-'
      },
      {
        accessorKey: "reco",
        header: "reco",
        size: 50,
        accessorFn: (row) => row.reco !== null && row.reco !== 0 ? Math.round(row.reco) : '-'
      },
      {
        accessorKey: "apr",
        header: "apr",
        size: 50,
        accessorFn: (row) => row.apr !== null && row.apr !== 0 ? Math.round(row.apr) : '-'
      },
      {
        accessorKey: 'allocatedTo',
        header: 'Allocated To',
        size: 100,
        Cell: ({ row }) => {
          const allocation = row.original.presentAllocation;
          const teamAllocation = row.original.presentTeamAllocations?.[0];

          if (allocation?.user) {
            // Individual processor allocation
            return (
              <Tooltip
                title={`Processor: ${allocation.user.firstName} ${allocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Person
                    sx={{
                      color: '#FFA726', // Orange color for processor
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`${allocation.user.firstName} ${allocation.user.lastName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else if (teamAllocation?.user) {
            // Team allocation
            return (
              <Tooltip
                title={`Team: ${teamAllocation.user.firstName} ${teamAllocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Groups
                    sx={{
                      color: '#42A5F5', // Blue color for team
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`Team ${teamAllocation.user.firstName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else {
            // Not allocated
            return (
              <Tooltip title="Not Allocated" placement="bottom">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Person
                    sx={{
                      color: '#9E9E9E', // Gray color for unallocated
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic',
                      color: '#9E9E9E'
                    }}
                  >
                    Unallocated
                  </Typography>
                </Box>
              </Tooltip>
            );
          }
        }
      },
      {
        accessorKey: "tags",
        header: "tag",
        size: 50,
        filterVariant: "multi-select",
        filterSelectOptions: tags,
        filterFn: (row, id, filterValue) => {
          if (!filterValue.length) return true;
          return row.tags?.some(tag => filterValue.includes(tag));
        },
        Cell: ({ cell }) => cell.getValue()?.length > 0 ? cell.getValue()[0].tag : '-'
      },
      {
        accessorKey: "deadline",
        header: "deadline",
        size: 70,
        accessorFn: (row) => {
          const date = row.deadline ? new Date(row.deadline) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      }
    ],
    [tags]
  );

  const columnVisibility = {
    platform: false,
    aby: false,
    regx: false,
    bids: false,
    tld_count: false,
    ESW: false,
    GDW: false,
    spr500: false,
    sprGDV: false,
    tags: false,
    reco: false,
    apr: false,
    host: false,
    deadline: false
  }

  const domainTable = useMaterialReactTable({
    columns: columns,
    data: data,
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
      columnVisibility: columnVisibility,
    },
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Sort
        </Button>
        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<FileDownloadOutlined />}
          onClick={() => handleExportData(table.getSelectedRowModel().rows)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Export
        </Button>
        <Box flexGrow={1} />
        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          size="small"
          sx={{
            minWidth: '100px',
            '& .MuiOutlinedInput-root': {
              height: '30px',
              fontSize: '0.875rem',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#8797A8',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '20px',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ fontSize: '1.2rem', color: '#8797A8', cursor: 'pointer' }}
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading,
      globalFilter: globalSearch
    },
  });

  const ltdTabTypes = ['ALL', 'ALD', 'BA', 'DC', 'DD', 'GD', 'NC', 'NS', 'PD', 'SD', 'SV'];

  return (<ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          My Domains
        </Typography>
        <Typography
          variant="h6"
          color='gray'
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          LTD Closed
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        {(isAdmin() || canAddLtd()) && 
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => { setOpenDialog1(true); }}
            sx={{
              height: 40,
              borderRadius: 100,
              textTransform: 'none'
            }}
          >
            Edit Domains
          </Button>
          <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} />
        </>}

      </Stack>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <TabContext value={ltdTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ minHeight: 36 }}
          >
            {ltdTabTypes.map((type) => (
              <Tab
                key={type}
                label={type}
                value={type}
                sx={{
                  backgroundColor: theme.palette.grey[200],
                  marginRight: 0.5,
                  borderRadius: '4px 4px 0 0',
                  minHeight: 36,
                  padding: '6px 12px',
                  fontSize: '0.75rem',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {ltdTabTypes.map((type) => (
          <TabPanel key={type} value={type} sx={{ padding: 0 }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MaterialReactTable table={domainTable} />
              </LocalizationProvider>
            </Box>
          </TabPanel>
        ))}
      </TabContext>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AllocationDialog
            open={openAllocate}
            onClose={handleCloseAllocate}
            selectedIds={idds}
            onSuccess={() => {
              setUpsuc1(true);
              fetchData();
            }}
            onError={() => setUpfail1(true)}
            onComplete={() => {
              setIdds([]);
            }}
          />
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <OverwriteDialog
            open={openExistingDomainsDialog}
            onClose={handleCloseExistingDomainsDialog}
            existingDomains={existingDomains}
            selectedDomains={selectedExistingDomains}
            onSelectAll={handleSelectAll}
            onDomainSelect={handleExistingDomainSelect}
            onOverwrite={() => {
              const domainsWithDates = selectedExistingDomains.map(selectedDomain => {
                const originalDomain = domains.find(d => d.domain === selectedDomain);
                return {
                  domain: selectedDomain,
                  edate: originalDomain?.edate,
                  platform: originalDomain?.platform
                };
              });
              handleUploadDomains(domainsWithDates, true);
            }}
          />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}