import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import format from 'date-fns/format';

const operators = {
  text: [
    { value: 'contains', label: 'Contains' },
    { value: 'equals', label: 'Equals' },
    { value: 'startsWith', label: 'Starts with' },
    { value: 'endsWith', label: 'Ends with' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' },
  ],
  number: [
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Not equals' },
    { value: 'greaterThan', label: 'Greater than' },
    { value: 'lessThan', label: 'Less than' },
    { value: 'between', label: 'Between' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' },
  ],
  date: [
    { value: 'equals', label: 'Equals' },
    { value: 'before', label: 'Before' },
    { value: 'after', label: 'After' },
    { value: 'between', label: 'Between' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' },
  ],
  boolean: [
    { value: 'equals', label: 'Equals' },
    { value: 'isEmpty', label: 'Is empty' },
    { value: 'isNotEmpty', label: 'Is not empty' },
  ],
};

// Add a constant for disabled columns
const DISABLED_COLUMNS = [
  'allocatedTo',
  'socials',
  'leads',
  'agents',
  'socials',
  'active',
  'actions',
  'emailState'
];

const MultiFilterDialog = ({ open, onClose, columns, initialFilters, onApplyFilter }) => {
  const [filters, setFilters] = useState(initialFilters || []);

  useEffect(() => {
    setFilters(initialFilters || []);
  }, [initialFilters]);

  const handleAddFilter = () => {
    setFilters([...filters, { id: '', operator: '', value: '' }]);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    const filter = newFilters[index];

    // Reset value and value2 when changing operator
    if (field === 'operator') {
      filter.value = '';
      filter.value2 = '';
    }

    // Handle special cases for value formatting
    if (field === 'value' || field === 'value2') {
      const columnType = getColumnType(filter.id);

      if (columnType === 'number') {
        const numValue = Number(value);
        if (!isNaN(numValue)) {
          value = numValue;
        }
      } else if (columnType === 'date' && value instanceof Date) {
        value = format(value, 'yyyy-MM-dd');
      } else if (columnType === 'boolean') {
        value = value === 'true' ? true : value === 'false' ? false : value;
      }
    }

    newFilters[index] = { ...filter, [field]: value };
    setFilters(newFilters);
  };

  const getColumnType = (columnId) => {
    const column = columns.find(col => col.id === columnId);
    if (!column) return 'text';

    if (['edate','dateOfUpload','createdAt','dateOfAlloc', 'orderDate','deadline'].includes(columnId)) return 'date';
    if (['p', 'len', 'bids', 'price', 'est', 'gdv', 'esw', 'gdw', 'apr', 'pp', 'status'].includes(columnId)) return 'number';
    if (['active', 'atWorkStation'].includes(columnId)) return 'boolean';
    return 'text';
  };

  const renderValueInput = (filter, index) => {
    const columnType = getColumnType(filter.id);
    const needsValueInput = !['isEmpty', 'isNotEmpty'].includes(filter.operator);

    if (!needsValueInput) return null;

    const isOperatorSelected = Boolean(filter.operator);

    switch (columnType) {
      case 'boolean':
        return (
          <Select
            size="small"
            value={filter.value}
            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
            disabled={!isOperatorSelected}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        );
      case 'number':
        return (
          <>
            <TextField
              size="small"
              type="number"
              value={filter.value === undefined || filter.value === '' ? '' : filter.value}
              onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
              placeholder="Value"
              disabled={!isOperatorSelected}
            />
            {filter.operator === 'between' && (
              <TextField
                size="small"
                type="number"
                value={filter.value2 === undefined || filter.value2 === '' ? '' : filter.value2}
                onChange={(e) => handleFilterChange(index, 'value2', e.target.value)}
                placeholder="Value 2"
                disabled={!isOperatorSelected}
              />
            )}
          </>
        );

      case 'date':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
              <DatePicker
                value={filter.value ? new Date(filter.value) : null}
                onChange={(newValue) => handleFilterChange(index, 'value', newValue)}
                disabled={!isOperatorSelected}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "Select date",
                    disabled: !isOperatorSelected,
                    sx: {
                      '& .MuiInputBase-root': {
                        height: '42px',
                        fontSize: '0.875rem',
                        backgroundColor: '#fff',
                        transition: 'all 0.2s',
                        '&:hover': {
                          backgroundColor: '#f8f9fa'
                        }
                      }
                    }
                  }
                }}
              />
              {filter.operator === 'between' && (
                <DatePicker
                  value={filter.value2 ? new Date(filter.value2) : null}
                  onChange={(newValue) => handleFilterChange(index, 'value2', newValue)}
                  disabled={!isOperatorSelected}
                  slotProps={{
                    textField: {
                      size: "small",
                      placeholder: "Select end date",
                      disabled: !isOperatorSelected,
                      sx: {
                        '& .MuiInputBase-root': {
                          height: '42px',
                          fontSize: '0.875rem',
                          backgroundColor: '#fff',
                          transition: 'all 0.2s',
                          '&:hover': {
                            backgroundColor: '#f8f9fa'
                          }
                        }
                      }
                    }
                  }}
                />
              )}
            </>
          </LocalizationProvider>
        );

      default: // text
        return (
          <TextField
            size="small"
            value={filter.value || ''}
            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
            placeholder="Value"
            disabled={!isOperatorSelected}
          />
        );
    }
  };

  const handleApply = () => {
    const validFilters = filters.filter(f => f.id && f.operator).map(filter => {
      const columnType = getColumnType(filter.id);
      const result = {
        id: filter.id,
        operator: filter.operator.toLowerCase(),
        value: filter.value,
        type: columnType
      };

      if (filter.operator === 'between') {
        // For between operator, use object with value and value2
        result.value = {
          value: filter.value,
          value2: filter.value2
        };
      }

      return result;
    });

    onApplyFilter(validFilters);
  };

  const handleReset = () => {
    setFilters([]);
    onApplyFilter([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
          px: 3,
          typography: 'h6',
          borderBottom: '1px solid #e0e0e0',
          color: '#1976d2',
          backgroundColor: '#fff'
        }}
      >
        Filters
      </DialogTitle>
      <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
        <Stack
          spacing={2}
          sx={{
            mt: 3,
            p: 3,
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
          }}
        >
          {filters.map((filter, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Select
                value={filter.id}
                onChange={(e) => handleFilterChange(index, 'id', e.target.value)}
                size="small"
                displayEmpty
                placeholder="Select Column"
                sx={{
                  minWidth: 130,
                  height: '42px',
                  backgroundColor: '#fff',
                  fontSize: '0.875rem',
                  '& .MuiSelect-select': {
                    fontSize: '0.875rem'
                  },
                  '&:hover': {
                    backgroundColor: '#f8f9fa'
                  }
                }}
              >
                <MenuItem value="" disabled sx={{ fontSize: '0.875rem' }}>Select Column</MenuItem>
                {columns.map((column) => (
                  <MenuItem 
                    key={column.id} 
                    value={column.id} 
                    sx={{ fontSize: '0.875rem' }}
                    disabled={DISABLED_COLUMNS.includes(column.id)}
                  >
                    {column.header}
                  </MenuItem>
                ))}
              </Select>

              <Select
                size="small"
                value={filter.operator}
                onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                displayEmpty
                placeholder="Select Operator"
                sx={{
                  minWidth: 130,
                  height: '42px',
                  fontSize: '0.875rem',
                  backgroundColor: '#fff',
                  '&:hover': {
                    backgroundColor: '#f8f9fa'
                  }
                }}
                disabled={!filter.id}
              >
                <MenuItem value="" disabled sx={{ fontSize: '0.875rem' }}>Select Operator</MenuItem>
                {filter.id && operators[getColumnType(filter.id)].map((op) => (
                  <MenuItem key={op.value} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>

              {renderValueInput(filter, index)}

              <IconButton
                onClick={() => handleRemoveFilter(index)}
                size="small"
                sx={{
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.04)'
                  }
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={handleAddFilter}
            variant="outlined"
            size="small"
            sx={{
              height: '38px',
              textTransform: 'none',
              borderRadius: 1,
              fontWeight: 500,
              borderColor: '#1976d2',
              color: '#1976d2',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                borderColor: '#1976d2'
              }
            }}
          >
            Add Filter
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: 2,
          px: 3,
          borderTop: '1px solid #e0e0e0',
          backgroundColor: '#fff'
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={handleReset}
          variant="outlined"
          sx={{
            mr: 1,
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Reset
        </Button>
        <Button
          onClick={handleApply}
          variant="contained"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            px: 3,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiFilterDialog;