import { Box, Button, CircularProgress, FormControl, FormGroup, InputLabel, MenuItem, Select, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useState } from "react";
import { allocateToProcessorUniversally, allocateToTeamUniversally, getAllTeamsAndUsers } from "../api";
import { PanToolAltOutlined } from "@mui/icons-material";

export default function Bulkallocator() {
  const [team, setTeam] = useState("");
  const [teams, setTeams] = useState([]);
  const [processors, setProcessors] = useState([]);

  const [value, setValue] = React.useState('');
  const [n, setN] = useState(0);
  const [d, setD] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ltdDomainsNotAllowed, setLtdDomainsNotAllowed] = useState("");
  const [ownedDomainsNotAllowed, setOwnedDomainsNotAllowed] = useState("");
  const [domainsNotFound, setDomainsNotFound] = useState("");

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    getAllTeamsAndUsers().then((res) => {
      setTeams(res.data.teams);
      setProcessors(res.data.users);
    })
  }, [])

  return (
  <ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5} width='100%'>
      <Stack direction='row' width='100%' alignItems='center' spacing={2}>
        <Typography variant="h6" sx={{
          textAlign: 'left',
          fontWeight: 'bold',
          position: 'relative',
        }}>Bulk Allocator</Typography>
        <Box flexGrow={1}></Box>

        <FormGroup sx={{ width: '200px' }}>
          <FormControl size='small' fullWidth margin="normal">
            <InputLabel id="team-label">Select Allocatee</InputLabel>
            <Select //disabled={checked}
              labelId="team-label"
              id="team-select"
              value={team}
              label="Select Allocatee"
              onChange={handleTeamChange}
            >
              {teams.map((child) => {
                return child.firstName ? <MenuItem value={child.email}>Team {child.firstName + " " + child.lastName}</MenuItem> : null;
              })}
              {processors.map((child) => {
                return child.firstName ? <MenuItem value={child.email + ",E"}>{child.firstName + " " + child.lastName}</MenuItem> : null;
              })}
            </Select>
          </FormControl>
        </FormGroup>
        <Button
          disabled={value.length <= 0 || team.length <= 0 || loading}
          variant="text"

          //color="primary"
          startIcon={loading ? <CircularProgress /> : <PanToolAltOutlined />}
          onClick={() => {
            setLoading(true);
            let arr = value.split('\n');
            console.log(arr);
            if (arr.length > 0 && !loading) {
              if (team.split(',').length == 2) {
                allocateToProcessorUniversally(arr, team.split(',')[0]).then((res) => {
                  console.log(res.data);
                  setN(res.data.n);
                  setD(res.data.d);
                  setLtdDomainsNotAllowed(res.data.ltdDomainsNotAllowed);
                  setOwnedDomainsNotAllowed(res.data.ownedDomainsNotAllowed);
                  setDomainsNotFound(res.data.domainsNotFound);
                  setLoading(false);
                }).catch((err) => {
                  console.log(err);
                  setError(true);
                  setN(0);
                  setD(0);
                  setLtdDomainsNotAllowed("");
                  setOwnedDomainsNotAllowed("");
                  setDomainsNotFound("");
                  setLoading(false);
                })
              }
              else {
                allocateToTeamUniversally(arr, team).then((res) => {
                  console.log(res.data);
                  setN(res.data.n);
                  setD(res.data.d);
                  setLtdDomainsNotAllowed(res.data.ltdDomainsNotAllowed);
                  setOwnedDomainsNotAllowed(res.data.ownedDomainsNotAllowed);
                  setDomainsNotFound(res.data.domainsNotFound);
                  setLoading(false);
                }).catch((err) => {
                  console.log(err);
                  setError(true);
                  setN(0);
                  setD(0);
                  setLtdDomainsNotAllowed("");
                  setOwnedDomainsNotAllowed("");
                  setDomainsNotFound("");
                  setLoading(false);
                })
              }
            }
            else {
              setLoading(false);
            }
          }}
          sx={{
            textTransform: 'none'
          }}
        >
          {loading ? "Allocating..." : "Allocate"}
        </Button>
      </Stack>
      <Stack alignItems='flex-start' spacing={1.5}>
        <Typography color='text.secondary'>
          Enter the list of domains:
        </Typography>
        <TextField
          id="outlined-multiline-static"
          name='domains'
          placeholder="Domains"
          multiline
          rows={15}
          fullWidth
          onChange={handleChange}
          value={value}

        />
      </Stack>
      <Stack direction='column' spacing={2} alignItems='flex-start'>
        {n > 0 && d > 0 && <Typography color='text.secondary' sx={{ mt: 2 }}>
          {n}/{d} domains allocated
        </Typography>}
        {loading && <Typography>Allocating domains...</Typography>}
        {n === 0 && d > 0 && (
          <Typography color='error'>
            No domains have been allocated.
          </Typography>
        )}
        {d > 0 && ltdDomainsNotAllowed && (
          <Typography color='error'>
            Not authorised to allocate Ltd domains: {ltdDomainsNotAllowed}
          </Typography>
        )}
        {d > 0 && ownedDomainsNotAllowed && (
          <Typography color='error'>
            Not authorised to allocate owned domains: {ownedDomainsNotAllowed}
          </Typography>
        )}
        {d > 0 && domainsNotFound && (
          <Typography color='error'>
            Domains not found: {domainsNotFound}
          </Typography>
        )}
      </Stack>
    </Stack>
  </ThemeProvider>
  )
}