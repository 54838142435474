import { Box, Button, Stack, ThemeProvider } from "@mui/material";
import { theme } from "../Theme";
import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { getEmailsBank, allocateToEmailProcessor } from "../api";
import { useUser } from "../UserProvider";

export default function EmailClaimHub() {
  const { user } = useUser();
  const [domains, setDomains] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleClaim = async () => {
    try {
      await allocateToEmailProcessor(selectedIds, user.email);
      // Handle success
    } catch (error) {
      // Handle error
    }
  };

  const columns = [
    {
      id: 'domain',
      header: 'Domain',
      accessorKey: 'domain',
    },
    {
      id: 'deadline',
      header: 'Deadline',
      accessorFn: (row) => new Date(row.deadline).toLocaleDateString(),
    },
    // Add more columns
  ];

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Button onClick={handleClaim}>Claim Selected</Button>
        <MaterialReactTable 
          columns={columns}
          data={domains}
          enableRowSelection
          onRowSelectionChange={setSelectedIds}
        />
      </Stack>
    </ThemeProvider>
  );
}
