import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add as AddIcon } from '@mui/icons-material';

const DISABLED_COLUMNS = [
  'allocatedTo',
  'socials',
  'leads',
  'agents',
  'socials',
  'active',
  'actions',
  'emailState'
];

function MultiSortDialog({ open, onClose, columns, initialSorting, onApplySort }) {
  const [sortCriteria, setSortCriteria] = useState([{ id: '', desc: false }]);

  useEffect(() => {
    if (open) {
      setSortCriteria(initialSorting.length > 0 ? initialSorting : [{ id: '', desc: false }]);
    }
  }, [open, initialSorting]);

  const handleAddSortCriteria = () => {
    setSortCriteria([...sortCriteria, { id: '', desc: false }]);
  };

  const handleRemoveSortCriteria = (index) => {
    const newCriteria = [...sortCriteria];
    newCriteria.splice(index, 1);
    setSortCriteria(newCriteria);
  };

  const handleSortCriteriaChange = (index, field, value) => {
    const newCriteria = [...sortCriteria];
    newCriteria[index][field] = value;
    setSortCriteria(newCriteria);
  };

  const handleApplySort = () => {
    const validSortCriteria = sortCriteria.filter((criteria) => criteria.id);
    onApplySort(validSortCriteria);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
          px: 3,
          typography: 'h6',
          borderBottom: '1px solid #e0e0e0',
          color: '#1976d2',
          backgroundColor: '#fff'
        }}
      >
        Sort Options
      </DialogTitle>
      <DialogContent
        sx={{
          p: 3,
          backgroundColor: '#f8f9fa'
        }}
      >
        <Box
          sx={{
            mt: 3,
            p: 3,
            backgroundColor: '#fff',
            borderRadius: 2,
            boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
          }}
        >
          {sortCriteria.map((criteria, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 2
              }}
            >
              <Select
                value={criteria.id}
                onChange={(e) => handleSortCriteriaChange(index, 'id', e.target.value)}
                size="small"
                displayEmpty
                sx={{
                  minWidth: 220,
                  height: '42px',
                  backgroundColor: '#fff',
                  fontSize: '0.875rem',
                  '& .MuiSelect-select': {
                    fontSize: '0.875rem'
                  },
                  '&:hover': {
                    backgroundColor: '#f8f9fa'
                  }
                }}
              >
                <MenuItem value="" sx={{ fontSize: '0.875rem' }}>Select a column</MenuItem>
                {columns.map((column) => (
                  <MenuItem 
                    key={column.accessorKey} 
                    value={column.accessorKey} 
                    sx={{ fontSize: '0.875rem' }}
                    disabled={DISABLED_COLUMNS.includes(column.accessorKey)}
                  >
                    {column.header}
                  </MenuItem>
                ))}
              </Select>

              <RadioGroup
                row
                value={criteria.desc ? 'desc' : 'asc'}
                onChange={(e) => handleSortCriteriaChange(index, 'desc', e.target.value === 'desc')}
                sx={{ marginLeft: 'auto' }}
              >
                <FormControlLabel
                  value="asc"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.875rem' }}>A to Z</Typography>}
                />
                <FormControlLabel
                  value="desc"
                  control={<Radio size="small" />}
                  label={<Typography sx={{ fontSize: '0.875rem' }}>Z to A</Typography>}
                />
              </RadioGroup>

              <IconButton
                onClick={() => handleRemoveSortCriteria(index)}
                size="small"
                sx={{
                  color: '#666',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}

          <Button
            startIcon={<AddIcon />}
            onClick={handleAddSortCriteria}
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              height: '38px',
              textTransform: 'none',
              borderRadius: 1,
              fontWeight: 500,
              borderColor: '#1976d2',
              color: '#1976d2',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                borderColor: '#1976d2'
              }
            }}
          >
            Add Sort Column
          </Button>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          py: 2,
          px: 3,
          borderTop: '1px solid #e0e0e0',
          backgroundColor: '#fff'
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            setSortCriteria([{ id: '', desc: false }]);
            onApplySort([]);
            onClose();
          }}
          variant="outlined"
          sx={{
            mr: 1,
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Reset
        </Button>
        <Button
          onClick={handleApplySort}
          variant="contained"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            px: 3,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MultiSortDialog;
