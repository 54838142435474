import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography, Checkbox, Badge, Tooltip, Tabs, Tab, OutlinedInput, Chip, ListItemText } from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import UploadCSVDialog from "../UploadCSVDialog";
import { canAddOwned, isAdmin, isManager } from "../msalService";
import { Add, Edit, PanToolAltOutlined, Sort as SortIcon, Person, Groups } from "@mui/icons-material";
import { allocateDomainToProcessor, uploadOwnedDomainss, uploadAldDomainss, allocateDomainToTeam, editDomain, editOwnedDomains, getAllOwnedDomain, getAllOwnedDomainsWithPagination, getAllTags, getRegistrars, uploadDomains, uploadOwnedDomains,  allocateDomainToTeams,
  allocateDomainToProcessors, getAllPortfolioDomains} from "../api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from "../UserProvider";
import UploadCSVDialogOwnedDomains from "../Common/UploadCSVDialogOwnedDomains";
import FilterListIcon from '@mui/icons-material/FilterList';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import AllocationDialog from '../Common/AllocationDialog';
import OverwriteDialog from '../Common/OverwriteDialog';

export default function MyDomainsOwned() {

  const [domains, setDomains] = useState([]);
  const [editDomains, setEditDomains] = useState([]);

  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [registrars, setRegistrars] = useState([]);
  const [regs, setRegs] = useState([]);
  const [registrar, setRegistrar] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [upsuc2, setUpsuc2] = useState(false);
  const [upfail2, setUpfail2] = useState(false);

  let [idds, setIdds] = React.useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const { user, updateUser } = useUser();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50, });
  const [totalRowCount, setTotalRowCount] = useState(0);

  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      getAllPortfolioDomains({
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
        portfolio: "owned"
      })
        .then(response => {
          if (response && response.data) {
            setData(response.data.content);
            setTotalRowCount(response.data.totalElements);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalRowCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchData:', error);
      setLoading(false);
    }
  }, [columnFilters, sorting, pagination, globalSearch]);

  // Add handlers for filter and sort dialogs
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]);

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleClose1 = () => {
    setOpenDialog1(false);
  }

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
    setSelectedUsers([]);
    setExistingDomains([]);
    setSelectedExistingDomains([]);
    setOpenExistingDomainsDialog(false);
  };

  const handleCloseExistingDomainsDialog = () => {
    setOpenExistingDomainsDialog(false);
    setSelectedExistingDomains([]);
    setExistingDomains([]);
    setDomains([]);
    if (idds.length > 0) {
      setOpenAllocate(true);
    }
  };

  const handleSelectAll = (event) => {
    setSelectedExistingDomains(event.target.checked ? existingDomains : []);
  };

  const handleExistingDomainSelect = (event) => {
    const { value } = event.target;
    setSelectedExistingDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
  };

  const handleUploadDomains = async (domains, registrar = "", overwrite = false) => {
    try {
      const res = await uploadOwnedDomainss(domains, registrar, overwrite);
      const newIds = res.data.savedDomainIds;
      setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));

      if (res.data.existingDomains.length > 0) {
        setExistingDomains(res.data.existingDomains);
        setOpenExistingDomainsDialog(true);
      } else {
        setOpenAllocate(true);
        setUpsuc(true);
        setDomains([]);
        handleCloseExistingDomainsDialog();
      }
      fetchData();
    } catch (err) {
      console.log(err);
      setDomains([]);
      setUpfail(true);
    }
  };

  useEffect(() => {
    if (domains && domains.length > 0) {
      handleUploadDomains(domains, registrar)
    }
  }, [domains, registrar]);

  useEffect(() => {
    if (editDomains && editDomains.length > 0)
      editOwnedDomains(editDomains)
        .then((res) => {
          console.log(res);
          setUpsuc(true);
          setEditDomains([]);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [editDomains]);


  useEffect(() => {
    fetchData();
    getAllTags()
      .then((res) => {
        const arr = res.data.map((ar) => ar.tag);
        console.log(arr);
        setTags(arr);
      })
      .catch((err) => {
        console.log(err);
      });
    getRegistrars()
      .then((res) => {
        console.log(res.data);
        setRegistrars(res.data);
        const rr = res.data.map((r) => {
          return r.registrar;
        });
        setRegs(rr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pagination.pageIndex, pagination.pageSize]);

  const isNewDomain = (dateString) => {
    const uploadDate = new Date(dateString);
    const currentDate = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return uploadDate > threeMonthsAgo;
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: 'domain',
        id: 'domain',
        header: 'Domain',
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isNewDomain(row.original.dateOfUpload) ? (
              <Badge
                badgeContent="NEW"
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    color: 'white',
                    fontSize: '0.5rem',
                    height: '10px',
                    minWidth: '16px',
                    padding: '0 2px',
                  }
                }}
              >
                <Box sx={{ pr: 2 }}>{row.original.domain}</Box>
              </Badge>
            ) : (
              row.original.domain
            )}
          </Box>
        ),
      }, 
      {
        id: 'dateOfUpload',
        header: 'Add Date',
        accessorFn: (originalRow) => { const date = new Date(originalRow.dateOfUpload); return date },
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
        filterVariant: 'date-range',
        enableEditing: false,
        Edit: () => null,
      }, 
      {
        id: 'allocatedTo',
        header: 'Allocated To',
        Cell: ({ row }) => {
          const allocation = row.original.presentAllocation;
          const teamAllocation = row.original.presentTeamAllocations?.[0];

          if (allocation?.user) {
            return (
              <Tooltip
                title={`Processor: ${allocation.user.firstName} ${allocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Person
                    sx={{
                      color: '#FFA726', // Orange color for processor
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`${allocation.user.firstName} ${allocation.user.lastName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          }

          if (teamAllocation?.user) {
            return (
              <Tooltip
                title={`Team: ${teamAllocation.user.firstName} ${teamAllocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Groups
                    sx={{
                      color: '#42A5F5', // Blue color for team
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`Team ${teamAllocation.user.firstName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Not Allocated" placement="bottom">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <Person
                  sx={{
                    color: '#9E9E9E', // Gray color for unallocated
                    fontSize: '1.2rem'
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '0.7rem',
                    fontStyle: 'italic',
                    color: '#9E9E9E'
                  }}
                >
                  Unallocated
                </Typography>
              </Box>
            </Tooltip>
          );
        },
        size: 180, // Fixed width for the column
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      }, 
      {
        accessorKey: 'cost',
        id: 'cost',
        header: 'Cost',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'EST',
        id: 'EST',
        header: 'EST',
        muiEditTextFieldProps: {
          type: 'number',
        }
      },
      {
        accessorKey: 'GDV',
        id: 'GDV',
        header: 'GDV',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'salePrice',
        id: 'salePrice',
        header: 'Sale Price',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'reserve',
        id: 'reserve',
        header: 'Reserve',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'minOffer',
        id: 'minOffer',
        header: 'Min Offer',
        muiEditTextFieldProps: {
          type: 'number',
        }
      },
      {
        accessorKey: 'category',
        id: 'category',
        header: 'Type',
      }, 
      {
        accessorKey: 'categoryMain',
        id: 'categoryMain',
        header: 'Category',
      }, 
      {
        accessorKey: 'categorySedo',
        id: 'categorySedo',
        header: 'SEDO Category',
      }, 
      {
        accessorKey: 'categoryDan',
        id: 'categoryDan',
        header: 'DAN Category',
      }, 
      {
        accessorKey: 'tags',
        enableColumnFilterModes: false,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          if (filterValue.length == 0) return true; else { for (let i = 0; i < row.original.tags.length; i++) { if (filterValue.includes(row.original.tags[i].tag)) return true; } return false; }//return row.tags[0].tag === filterValue;
        },
        filterSelectOptions: tags,
        header: 'Tags',
        Cell: ({ cell }) => { if (cell.getValue().length > 0) return cell.getValue()[0].tag + (cell.getValue().length > 1 ? (cell.getValue().length - 1) : "") },
        enableEditing: false,
      },
      {
        id: 'registrar',
        header: 'Registrar',
        accessorFn: (row) => { if (row.registrar) return row.registrar.displayName; else return 'N/A' },
        editVariant: 'select',
        editSelectOptions: regs,
      },
      {
        accessorKey: 'whoIsEmail',
        id: 'whoIsEmail',
        header: 'WhoIs Email',
        editVariant: 'select',
        editSelectOptions: ['Namekart', 'Name.ai', 'Netherlands', 'Private', 'Other'],
      }, 
      {
        accessorKey: 'nameservers',
        id: 'nameservers',
        header: 'Nameservers',
        Cell: ({ cell }) => { if (cell.getValue().length > 0) return cell.getValue()[0].tag + (cell.getValue().length > 1 ? (cell.getValue().length - 1) : "") },
        enableEditing: false,
      },
      {
        accessorKey: 'lander',
        id: 'lander',
        header: 'Lander',
        editVariant: 'select',
        editSelectOptions: ['BuyNow', 'MakeOffer', 'RequestPrice', 'Other'],
      }, 
      {
        accessorKey: 'dan',
        id: 'dan',
        header: 'Dan',
        editVariant: 'select',
        editSelectOptions: ['Yes', 'No'],
      }, 
      {
        accessorKey: 'afternic',
        id: 'afternic',
        header: 'Afternic',
        editVariant: 'select',
        editSelectOptions: ['Yes', 'No', 'DLS'],
      }, 
      {
        accessorKey: 'sedo',
        id: 'sedo',
        header: 'Sedo',
        editVariant: 'select',
        editSelectOptions: ['Yes', 'No', 'MLS'],
      }, 
      {
        accessorKey: 'dls',
        id: 'dls',
        header: 'Dls',
        editVariant: 'select',
        editSelectOptions: ['Yes', 'No'],
      },
      {
        accessorKey: 'wby',
        header: 'WBY',
        muiEditTextFieldProps: {}
      }, 
      {
        accessorKey: 'aby',
        header: 'ABY',
        muiEditTextFieldProps: {}
      }, 
      {
        accessorKey: 'eby',
        header: 'EBY',
        muiEditTextFieldProps: {}
      }, 
      {
        accessorKey: 'reg',
        header: 'Reg',
        muiEditTextFieldProps: {type: 'number'}
      }, 
      {
        accessorKey: 'regx',
        header: 'Regx',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'sg',
        header: 'SG',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'cpc',
        header: 'CPC',
        muiEditTextFieldProps: {
          type: 'number',
        }
      },
      {
        accessorKey: 'platform',
        header: 'Platform',
      }, 
      {
        accessorKey: 'purchaseDate',
        header: 'Date',
      }, 
      {
        accessorKey: 'PP',
        header: 'Price',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'purchaseRenewal',
        header: 'Renewal',
        muiEditTextFieldProps: {
          type: 'number',
        }
      },
      {
        accessorKey: 'futureRenewal',
        header: 'Future Renewal',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'transferPrice',
        header: 'Transfer Price',
        muiEditTextFieldProps: {
          type: 'number',
        }
      }, 
      {
        accessorKey: 'authCode',
        header: 'Auth Code',
      }, 
      {
        accessorKey: 'autoRenew',
        accessorFn: (row) => { if (row.autoRenew) return 'Yes'; else return 'No' },
        header: 'Auto Renew',
        editVariant: 'select',
        editSelectOptions: ["Yes", 'No'],
      },
    ],
    [tags]
  );

  const domainTable = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    enableEditing: isAdmin(),
    editDisplayMode: 'modal',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 80,
        minSize: 60,
        maxSize: 100,
        muiTableHeadCellProps: {
          align: 'center',
          sx: {
            paddingRight: '8px',
            backgroundColor: '#FFC0CB', // Match the header background color
            color: 'white',
            fontWeight: 'bold',
            fontSize: '0.7rem',
            padding: '0px',
            margin: '0px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontStyle: 'italic',
          },
        },
        muiTableBodyCellProps: {
          align: 'center',
          sx: {
            paddingRight: '8px',
            fontSize: '0.7rem',
            padding: '0px',
            margin: '0px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontStyle: 'italic',
          },
        },
      },
      'mrt-row-select': {
        size: 50, // Set width for selection checkbox column
        enableResizing: false,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: '#FFC0CB', // Match the header background color
            color: 'white',
            padding: '0px',
            margin: '0px',
          },
        },
        muiTableBodyCellProps: {
          sx: {
            padding: '0px',
            margin: '0px',
          },
        },
      },
    },
    onEditingRowSave: ({ values, table, row }) => {
      editDomain(values, row.original.id).then((res) => {
        console.log(res); setData((prev) => {
          let arr = data.map((row) => { if (res.data.id == row.id) return res.data; else return row });
          return arr;
        })
        setUpsuc2(true)
        table.setEditingRow(null)
      }).catch((err) => { console.log(err); setUpfail2(true) });
      console.log(values);
    },

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Sort
        </Button>
        {isAdmin() && (
          <Button
            disabled={table.getSelectedRowModel().rows.length === 0}
            variant="outlined"
            startIcon={<PanToolAltOutlined />}
            onClick={() => {
              setOpenAllocate(true);
              let id = table.getSelectedRowModel().rows.map((row) => row.original.id);
              setIdds(id);
            }}
            sx={{
              textTransform: 'none',
              backgroundColor: '#8797A8',
              color: '#FFFFFF !important',
              padding: '4px 8px',
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: '#8797A8',
                opacity: 0.9,
              },
              '&.Mui-disabled': {
                backgroundColor: '#8797A8',
                opacity: 0.7,
              }
            }}
          >
            Allocate
          </Button>
        )}
        <Box flexGrow={1} />
        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          size="small"
          sx={{
            minWidth: '100px',
            '& .MuiOutlinedInput-root': {
              height: '30px',
              fontSize: '0.875rem',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#8797A8',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '20px',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon 
                  sx={{ fontSize: '1.2rem', color: '#8797A8', cursor: 'pointer' }} 
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    initialState: { 
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 }
    },
    state: { 
      pagination,
      sorting,
      columnFilters,
      isLoading: loading,
      globalFilter: globalSearch
    },
  });

  useEffect(() => {
    console.log(domainTable.getState().sorting);
  }, [domainTable.getState().sorting])

  return (
  <ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          My Domains
        </Typography>
        <Typography
          variant="h6"
          color='gray'
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          Owned
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        {(isAdmin() || canAddOwned()) && <><Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => { setOpenDialog(true); }}
          sx={{
            height: 40,
            borderRadius: 100,
            textTransform: 'none'
          }}
        >
          Add Domains
        </Button>
          <UploadCSVDialogOwnedDomains title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} setRegistrar={setRegistrar} registrars={registrars} />

          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => { setOpenDialog1(true); }}
            sx={{
              height: 40,
              borderRadius: 100,
              textTransform: 'none'
            }}
          >
            Enrich Domains
          </Button>
          <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} /></>}

      </Stack>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc2(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Changes Saved!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail2} autoHideDuration={2500} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail2(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Changes Not Saved!
        </Alert>
      </Snackbar>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>

          <AllocationDialog 
            open={openAllocate}
            onClose={handleCloseAllocate}
            selectedIds={idds}
            onSuccess={() => { setUpsuc1(true); fetchData();}}
            onError={() => setUpfail1(true)}
            onComplete={() => {setIdds([])}}
          />

          <OverwriteDialog
            open={openExistingDomainsDialog}
            onClose={handleCloseExistingDomainsDialog}
            existingDomains={existingDomains}
            selectedDomains={selectedExistingDomains}
            onSelectAll={handleSelectAll}
            onDomainSelect={handleExistingDomainSelect}
            onOverwrite={() => {
              const domainsWithDates = selectedExistingDomains.map(selectedDomain => {
                const originalDomain = domains.find(d => d.domain === selectedDomain);
                return {
                  domain: selectedDomain
                };
              });
              handleUploadDomains(domainsWithDates, registrar, true);
            }}
          />

          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <MaterialReactTable
            table={domainTable}
          />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}