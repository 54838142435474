import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { useCSVReader , formatFileSize, lightenDarkenColor, readString,} from "react-papaparse";
import { getGoogleSheetData } from "./api";
import { CSVLink } from "react-csv";

export default function UploadCSVDialogWork(props) 
{
    const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';
const parseCSV = (csvData) => {
  return new Promise((resolve, reject) => {
    const results = readString(csvData, {
      header: true,
      skipEmptyLines: true,
      transformHeader: header => header.toLowerCase().replace(/\s+/g, ''),
      complete: (results) => {
        resolve(results.data);
      },
      error: (error) => {
        reject(error);
      }
    });
    // Since readString is synchronous in react-papaparse, we manually resolve the promise with the results.
  });
};
const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: 140,//'100%',
    width: 350,
    justifyContent: 'center',
    padding: 20,
  } ,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 100,
    width: 350,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } ,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } ,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  } ,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [disabled1, setDisabled1] = useState(true);
    const [uploadError, setUploadError] = useState('');

    const [col, setCol] = React.useState([]);
const [val, setVal] = React.useState([]);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [googleSheetsUrl, setGoogleSheetsUrl] = useState('');
  const [urlError, setUrlError] = useState('');

  // Validation function for Google Sheets URL
  const validateGoogleSheetsUrl = (url) => {
      const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[^\/]+\/pub\?output=csv$/;
      return regex.test(url);
  };
  const validateHeaders = (dataArray, requiredHeaders) => {
    if (dataArray.length === 0) {
      return false; // No data to check
    }
    const firstRow = dataArray[0];
    return requiredHeaders.every(header => Object.hasOwnProperty.call(firstRow, header));
  };
  const handleGoogleSheetsUrlChange = (e) => {
      const url = e.target.value;
      setGoogleSheetsUrl(url);
      if (validateGoogleSheetsUrl(url)) {
        setUrlError('');
        setDisabled1(false);
      }
      else if(url === '')
      {
        setUrlError('');
      }
      else {
          setUrlError('URL must be of the format: https://docs.google.com/spreadsheets/d/e/<YOUR-DOCUMENT-LINK>/pub?output=csv');
      }
  };
  const handleSync = async () => {
    if (!googleSheetsUrl || !validateGoogleSheetsUrl(googleSheetsUrl)) return;

      try {
          // Assuming the Google Sheets URL is a direct link to the CSV export
          const response = await getGoogleSheetData(googleSheetsUrl);
          const csvData = response.data;

          // Use PapaParse or another library here to convert CSV data to a JavaScript array
          // For simplicity, let's assume we have a function parseCSV that does this
          const parsedData = await parseCSV(csvData); // Implement parseCSV according to your needs
          const requiredHeaders = ['domain', 'lead']; // Define your required headers here
          const isValid = validateHeaders(parsedData, requiredHeaders);
        console.log(parsedData)
          if (!isValid) {
            setUploadError('The CSV file is in the wrong format. It must contain "Domain" and "Lead" headers.');
            setDisabled(true)
            return; // Stop further processing
          }
          setUploadError('');
          // Now you can do something with parsedData, for example:
          setVal(parsedData);
          setDisabled(false);
      } catch (error) {
          console.error('Error fetching or parsing CSV data:', error);
      }
  };
 
    // Sample data array for CSV download
    const sampleData = [
        {
            domain: "VoxDomini.com",
            imp: "1",
            lead: "voxdomini.pl",
            name: "",
            primaryemail: "",
            secondaryemail: "office@voxdomini.pl",
            contactform: ""
        },
        {
            domain: "VoxDomini.com",
            imp: "1",
            lead: "voxdomini.com.br",
            name: "Rodolfo",
            primaryemail: "",
            secondaryemail: "rodolfomaciel55@gmail.com",
            contactform: ""
        },
        {
            domain: "VoxDomini.com",
            imp: "",
            lead: "voxteneo.com",
            name: "Mathieu ",
            primaryemail: "m.vanraemdonck@voxteneo.com",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "1",
            lead: "buzz-line.com",
            name: "",
            primaryemail: "",
            secondaryemail: "BuzzLinePH@gmail.com",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "1",
            lead: "warrenheimcorp.com",
            name: "",
            primaryemail: "",
            secondaryemail: "info@warrenheimcorp.com",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "1",
            lead: "buzzlinesounds.com",
            name: "",
            primaryemail: "",
            secondaryemail: "hornet@buzzlinesounds.com",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "1",
            lead: "buzzlinemediagroup.com",
            name: "",
            primaryemail: "",
            secondaryemail: "buzzlinemediagroup@gmail.com",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "",
            lead: "buz-line.com",
            name: "Brad",
            primaryemail: "brad@buz-line.com",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "BuzzLines.com",
            imp: "",
            lead: "guggenmossales.com",
            name: "Greg",
            primaryemail: "greg@guggenmossales.com",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "euromince.sk",
            name: "",
            primaryemail: "",
            secondaryemail: "info@euromince.sk",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "euromince.info",
            name: "",
            primaryemail: "",
            secondaryemail: "obchod@euromince.info",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "euronumis.eu",
            name: "",
            primaryemail: "",
            secondaryemail: "eshop@numis.sk",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "euromince.net",
            name: "",
            primaryemail: "dano@euromince.net",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "eurocoins.co.uk",
            name: "",
            primaryemail: "",
            secondaryemail: "info@chards.co.uk",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "leuchtturm.com",
            name: "Axel",
            primaryemail: "a.stuerken@leuchtturm.com",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "eurocoinhouse.com",
            name: "",
            primaryemail: "",
            secondaryemail: "info@eurocoinhouse.com",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "coinslv.com",
            name: "",
            primaryemail: "",
            secondaryemail: "info@coinslv.com",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "euro-coins.tv",
            name: "",
            primaryemail: "",
            secondaryemail: "",
            contactform: "CF"
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "emk.com",
            name: "Marc",
            primaryemail: "bach@emk.com",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "maltacoincentre.com",
            name: "Joseph",
            primaryemail: "espositoj@centralbankmalta.org",
            secondaryemail: "",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "coinshopgroup.com",
            name: "",
            primaryemail: "",
            secondaryemail: "info@coinshopgroup.com",
            contactform: ""
        },
        {
            domain: "EuroMince.com",
            imp: "",
            lead: "electacollections.com",
            name: "",
            primaryemail: "",
            secondaryemail: "info@electacollections.com",
            contactform: ""
        }
    ];

    // Prepare the CSV data
    const headers = [
        { label: "Domain", key: "domain" },
        { label: "Imp", key: "imp" },
        { label: "Lead", key: "lead" },
        { label: "Name", key: "name" },
        { label: "Primary Email", key: "primaryemail" },
        { label: "Secondary Email", key: "secondaryemail" },
        { label: "Contact Form", key: "contactform" }
    ];

    const csvReport = {
        data: sampleData,
        headers: headers,
        filename: 'sampleData.csv'
    };

    return (
        <Dialog maxWidth='xs' fullWidth open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          <Stack direction='column'>
            <Typography variant='h6'>Add {props.title}</Typography>
            <Typography fontSize={10}  >Work uploaded here will get appended to your current work file, It will not replace your work. Your complete work will be visible in Work Station below.</Typography>
        </Stack>
        </DialogTitle>
        <DialogContent>
        <CSVReader 
  onUploadAccepted={(results) => {
    console.log('---------------------------');
    console.log(results);
    console.log('---------------------------');
    const requiredHeaders = ['domain', 'lead']; // Define your required headers here
    const isValid = validateHeaders(results.data, requiredHeaders);
  
    if (!isValid) {
      setUploadError('The CSV file is in the wrong format. It must contain "Domain" and "Lead" headers.');
      setDisabled(true);
      return; // Stop further processing
    }
    setVal(results.data)
    setZoneHover(false);
    setDisabled(false);

  }}


  config={{ worker:false, header:true, transformHeader:function(h) {
    return h.toLowerCase().replace(/\s/g,'').trim();
  }}}
  onDragOver={(event) => {
    event.preventDefault();
    setZoneHover(true);
  }}
  onDragLeave={(event) => {
    event.preventDefault();
    setZoneHover(false);
  }}
>
  {({
    getRootProps,
    acceptedFile,
    ProgressBar,
    getRemoveFileProps,
    Remove,
  }) => (
    <>
      <div
        {...getRootProps()}
        style={Object.assign(
          {},
          styles.zone,
          zoneHover && styles.zoneHover
        )}
      >
        {acceptedFile ? (
          <>
            <div style={styles.file}>
              <div style={styles.info}>
                <span style={styles.size}>
                  {formatFileSize(acceptedFile.size)}
                </span>
                <span style={styles.name}>{acceptedFile.name}</span>
              </div>
              <div style={styles.progressBar}>
                <ProgressBar />
              </div>
              <div
                {...getRemoveFileProps()}
                style={styles.remove}
                onMouseOver={(event) => {
                  event.preventDefault();
                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                }}
                onMouseOut={(event) => {
                  event.preventDefault();
                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                }}
              >
                <Remove color={removeHoverColor} />
              </div>
            </div>
          </>
        ) : (
          'Drop CSV file here or click to upload'
        )}
      </div>
    </>
  )}
</CSVReader>

        </DialogContent>
        <Typography variant='caption' align='center' >OR</Typography>
        <DialogContent>
                {/* Existing CSVReader component... */}

                {/* Add TextField and Sync Button here */}
                <Stack direction='column'><Stack direction='row' mt={2} spacing={2}> 
                    <TextField
                    size="small"
                        fullWidth
                        label="Google Sheets URL"
                        variant="outlined"
                        value={googleSheetsUrl}
                        onChange={handleGoogleSheetsUrlChange}
                    />
               
                    <Button size='small' disabled={disabled1} variant="text" onClick={handleSync}>Sync</Button>
                    </Stack>
                    {(urlError && urlError.length > 0) && <Typography color='error' variant='caption'>{urlError}</Typography>}
                    {(uploadError && uploadError.length > 0) && <Typography color='error' variant='caption'>{uploadError}</Typography>}
</Stack>
            </DialogContent>
            <DialogActions >
              <Stack direction='row' justifyContent='space-between' width='100%' pl={2}>
            {/* {<Button variant="contained" onClick={handleSync}>Sync</Button>} */}

            <CSVLink  {...csvReport} style={{ textDecoration: 'none' }}>
                   <Typography variant='caption' >Download Sample CSV</Typography> 
                </CSVLink>
                
                <Button disabled={disabled} onClick={() => { props.setState(val); setVal([]); setDisabled(true); props.handleClose(); }}>Upload</Button>
                {/* Add the Download Sample CSV Button using CSVLink */}
                </Stack>
                
            </DialogActions>
      </Dialog>
    )
}