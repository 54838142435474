import React, { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Pagination,
  IconButton,
  Stack,
  Chip,
  Tooltip,
} from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LeadsIcon from "@mui/icons-material/Leaderboard"; // This is a placeholder, replace with an appropriate icon
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Person } from "@mui/icons-material";
const domainsData = [
  {
    domain: "Bottle.ai",
    value: "4000",
    processor: "Nandini",
    date: "07-12-23",
    leads: "20 L",
  },
  {
    domain: "Virtualreality.ai",
    value: "7000",
    processor: "Priyanka",
    date: "07-12-23",
    leads: "40 L",
  },
  {
    domain: "Bottle.ai",
    value: "4000",
    processor: "Nandini",
    date: "07-12-23",
    leads: "20 L",
  },
  {
    domain: "Virtualreality.ai",
    value: "7000",
    processor: "Priyanka",
    date: "07-12-23",
    leads: "40 L",
  },
  {
    domain: "Bottle.ai",
    value: "4000",
    processor: "Nandini",
    date: "07-12-23",
    leads: "20 L",
  },
  {
    domain: "Virtualreality.ai",
    value: "7000",
    processor: "Priyanka",
    date: "07-12-23",
    leads: "40 L",
  },
  // ... other domain items
];

export const OldDomainsList = (props) => {
  const rowsPerPageOptions = [5, 10, 25,50,100];
  const domainsData =props.data;
  
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page after changing rows per page
  };

  // Calculate the domains to display on the current page

  const [sortConfig, setSortConfig] = useState({
    key: "domain.EST",
    direction: "ascending",
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedDomains = [...domainsData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const paginatedDomains = sortedDomains.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper", p: 2 }}>
      <Stack direction='row' justifyContent='space-between' paddingInline={2}>
      <Typography variant="h6" color='primary' sx={{ fontWeight: "bold", mb: 2 }}>
        {props.title}
      </Typography>
      <Typography variant="h6" color='primary' sx={{ fontWeight: "bold", mb: 2 }}>
        {domainsData.length}
      </Typography>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2,pl:2 }}>
        <Typography variant="subtitle1" color='primary' sx={{ fontWeight: "bold" }}>
          Domains
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
          color='primary'
            variant="subtitle1"
            sx={{ fontWeight: "bold" }}
            onClick={() => handleSort("value")}
          >
            EST
          </Typography>
          <IconButton onClick={() => handleSort("value")} size="small" sx={{pl:0}}>
            {sortConfig.direction === "ascending" ? (
              <ArrowUpwardIcon sx={{fontSize:14}} />
            ) : (
              <ArrowDownwardIcon sx={{fontSize:14}} />
            )}
          </IconButton>
        </Box>
      </Box>
      <List>
        {paginatedDomains.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              alignItems="flex-start"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
              
                <ListItemText
                  primary={<Stack direction='row' spacing={0.5} alignItems='center'><Tooltip title={item.domain.domain}><Typography maxWidth={120} noWrap fontSize={17} fontWeight='bold'>{item.domain.domain}</Typography></Tooltip><Chip label={item.domain.portfolio.toUpperCase()} size="small" sx={{backgroundColor:item.domain.portfolio==='ltd'?'#FFC682':'#C6CFFF',width:item.domain.ltd?15:25,height:9,fontSize:5,'& .MuiChip-label': { 
                    padding: '1px 2px' 
                  } }}/></Stack>}
                  secondary={
                    <><Tooltip title={item.user.firstName+" "+item.user.lastName}>
                      <Person sx={{ fontSize: "small", mr: 0.5 , mb:-0.3,pb:0 }} />
                      {item.user.firstName}
                      </Tooltip>
                    </>
                  }
                />
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <ListItemText
                  primary={<Typography fontSize={17} fontWeight='bold'>{`$${item.domain.EST}`}</Typography>}
                  secondary={
                    <Stack  direction='row' alignItems='center'>
                      <CalendarTodayIcon sx={{ fontSize: "small", mr: 0.5 }} />
                      <Typography variant='subtitle2' fontSize={10}>{new Date(item.dateOfAlloc).toLocaleDateString()}</Typography>
                      
                      <LeadsIcon sx={{ fontSize: "small", ml: 1, mr: 0.5}} />
                      <Typography sx={{color:'red'}} m={0} p={0} variant='subtitle2' fontWeight='bold' fontSize={15}>{item.leads.length+" L"}</Typography>
                    </Stack>
                  }
                />
              </Box>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography variant="caption" sx={{ mr: 2 }}>
          Rows per page:
        </Typography>
        <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <Pagination
          component="div"
          count={Math.ceil(domainsData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          sx={{ ml: 2 }}
        />
      </Box>
    </Box>
  );
};
