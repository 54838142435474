import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, TextField, Button, Snackbar, Alert,
  IconButton,
  Tooltip
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { theme } from "../Theme";
import { getPaginatedAnalysisData, shortlistAnalysisHub, shortlistDomain, unshortlistDomain } from "../api";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { ThumbUp, ThumbDown, StarBorder, Star } from '@mui/icons-material';
import { useUser } from '../UserProvider';
import { getUserName } from '../msalService';

export default function AnalysisHub() {
  const [domains, setDomains] = useState([]);
  const [tabValue, setTabValue] = useState('All');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchDate, setSearchDate] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { user, updateUser,olevel } = useUser();

  const listTypes = ['All', 'SN', 'DC', 'PD', 'DD', 'GD', 'RP'];
  const userName=getUserName();

  useEffect(() => {
    fetchDomains();
    table.resetRowSelection();
  }, [pagination.pageIndex, pagination.pageSize, tabValue, searchDate]);
  useEffect(() => {
    console.log("domains", domains);
  }, [domains]);

  const handleShortlistChange = useCallback((rowId, newRating) => {
    shortlistAnalysisHub(rowId, newRating)
      .then(res => {
        setDomains(prevDomains => {
          return prevDomains.map(domain => 
            domain.id === res.data.id ? res.data : domain
          );
        });
        console.log(`Domain ${res.data.domain} shortlisted with rating ${newRating}`);
      })
      .catch(error => {
        console.error('Error updating shortlist:', error);
        // setDomains(prevDomains => {
        //   return [...prevDomains];
        // });
        // Optionally, you can add error handling here, such as showing an error message to the user
      });
  }, []);

  const handleShortlistChangeAfterAPI = useCallback((updatedRow) => {
        setDomains(prevDomains => {
          return prevDomains.map(domain => 
            domain.id === updatedRow.id ? updatedRow : domain
          );
        });
  }, []);

  const fetchDomains = () => {
    setLoading(true);
    const filters = {
      list_type: tabValue !== 'All' ? tabValue : '',
      process_date: searchDate,
    };
    getPaginatedAnalysisData(pagination.pageIndex, pagination.pageSize, filters)
      .then(res => {
        setDomains(res.data.content);
        setTotalElements(res.data.totalElements);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(true);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const hiddenColumns = {
    domain: true,
    list_type: true,
    appraised_value: true,
    appraised_wholesale_value: true,
    search_volume_exact_term: true,
    search_cpc_exact_term: true,
    search_ad_competition_exact_term: true,
    pagerank: true,
    wayback_records: true,
    wayback_age: true,
    whois_create_date: true,
    dmoz: true,
    similar_sales: true,
    end_user_buyers: true,
    tld_count: true,
    tld_count_dev: true,
    // Now the false values
    tld: false,
    type: false,
    category: false,
    sld: false,
    words: false,
    language: false,
    language_probability: false,
    sld_length: false,
    word_count: false,
    search_results_term: false,
    search_results_sld: false,
    search_ads_term: false,
    search_ads_sld: false,
    search_suggestion_term: false,
    search_suggestion_sld: false,
    search_volume_broad_term: false,
    search_cpc_broad_term: false,
    search_ad_competition_broad_term: false,
    ad_cost_broad_term: false,
    ad_cost_exact_term: false,
    alexa_traffic_rank: false,
    alexa_link_popularity: false,
    altavista_link_popularity: false,
    google_link_popularity: false,
    yahoo_link_popularity: false,
    altavista_link_saturation: false,
    google_link_saturation: false,
    yahoo_link_saturation: false,
    overture_term: false,
    overture_tld: false,
    overture_sld: false,
    whois_registrar: false,
    whois_expire_date: false,
    whois_update_date: false,
    whois_ns1: false,
    whois_ns2: false,
    whois_age: false,
    drop_date: false,
    auction_price: false,
    auction_bidders: false,
    auction_join_date: false,
    auction_end_date: false,
    auction_url: false,
    auction_traffic: false,
    auction_revenue: false,
    auction_title: false,
    auction_description: false,
    adult: false,
    yahoo: false,
    trademark_type: false,
    trademark_term: false,
    trademark_company: false,
    trademark_risk: false,
    trademark_probability: false,
    has_num: false,
    has_dash: false,
    extensions_taken: false,
    traffic_estimate: false,
    parking_revenue_estimate: false,
    development_revenue_estimate: false,
    process_date: false,
  };

  const columns = useMemo(() => [
    {
      id: 'starRating',
      header: 'Rating',
      size: 20,
      minSize: 10,
      extraMinSize: 10,
      Cell: ({ row }) => (
        <StarRatingButton
          rowId={row.original.id}
          domain={row.original.domain}
          acquShortlistedDomain={row.original.acquShortlistedDomain}
          userEmail={userName}
          onShortlistChange={handleShortlistChange}
          onShortlistChangeAfterAPI={handleShortlistChangeAfterAPI}
        />
      ),
    },
    { accessorKey: 'domain', header: 'Domain', size: 120 },
    { accessorKey: 'list_type', header: 'Type', size: 40 },
    { accessorKey: 'appraised_value', header: 'EST', size: 40 },
    { accessorKey: 'appraised_wholesale_value', header: 'ESW', size: 40 },
    { accessorKey: 'search_volume_exact_term', header: 'SV', size: 40 },
    { accessorKey: 'search_cpc_exact_term', header: 'CPC', size: 40 },
    { accessorKey: 'search_ad_competition_exact_term', header: 'COMP', size: 40 },
    { accessorKey: 'pagerank', header: 'PR', size: 40 },
    { accessorKey: 'wayback_records', header: 'WBR', size: 40 },
    { accessorKey: 'wayback_age', header: 'ABY', size: 40 },
    { accessorKey: 'whois_create_date', header: 'WBY', size: 40 },
    { accessorKey: 'dmoz', header: 'DMOZ', size: 40 },
    { accessorKey: 'similar_sales', header: 'SS', size: 40 },
    { accessorKey: 'end_user_buyers', header: 'EUP', size: 40 },
    { accessorKey: 'tld_count', header: 'REG', size: 40 },
    { accessorKey: 'tld_count_dev', header: 'REGD', size: 40 },
    // Now the false values
    { accessorKey: 'tld', header: 'TLD', size: 60 },
    { accessorKey: 'type', header: 'Type', size: 80 },
    { accessorKey: 'category', header: 'Category', size: 100 },
    { accessorKey: 'sld', header: 'SLD', size: 60 },
    { accessorKey: 'words', header: 'Words', size: 100 },
    { accessorKey: 'language', header: 'Lang', size: 80 },
    { accessorKey: 'language_probability', header: 'Lang Prob', size: 100 },
    { accessorKey: 'sld_length', header: 'SLD Len', size: 80 },
    { accessorKey: 'word_count', header: 'Word Count', size: 100 },
    { accessorKey: 'search_results_term', header: 'Search Res Term', size: 100 },
    { accessorKey: 'search_results_sld', header: 'Search Res SLD', size: 100 },
    { accessorKey: 'search_ads_term', header: 'Search Ads Term', size: 100 },
    { accessorKey: 'search_ads_sld', header: 'Search Ads SLD', size: 100 },
    { accessorKey: 'search_suggestion_term', header: 'Search Sug Term', size: 100 },
    { accessorKey: 'search_suggestion_sld', header: 'Search Sug SLD', size: 100 },
    { accessorKey: 'search_volume_broad_term', header: 'Search Vol Broad', size: 100 },
    { accessorKey: 'search_cpc_broad_term', header: 'Search CPC Broad', size: 100 },
    { accessorKey: 'search_ad_competition_broad_term', header: 'Ad Comp Broad', size: 100 },
    { accessorKey: 'ad_cost_broad_term', header: 'Ad Cost Broad', size: 100 },
    { accessorKey: 'ad_cost_exact_term', header: 'Ad Cost Exact', size: 100 },
    { accessorKey: 'alexa_traffic_rank', header: 'Alexa Rank', size: 100 },
    { accessorKey: 'alexa_link_popularity', header: 'Alexa Link Pop', size: 100 },
    { accessorKey: 'altavista_link_popularity', header: 'AltaVista Link Pop', size: 100 },
    { accessorKey: 'google_link_popularity', header: 'Google Link Pop', size: 100 },
    { accessorKey: 'yahoo_link_popularity', header: 'Yahoo Link Pop', size: 100 },
    { accessorKey: 'altavista_link_saturation', header: 'AltaVista Link Sat', size: 120 },
    { accessorKey: 'google_link_saturation', header: 'Google Link Sat', size: 100 },
    { accessorKey: 'yahoo_link_saturation', header: 'Yahoo Link Sat', size: 100 },
    { accessorKey: 'overture_term', header: 'Overture Term', size: 100 },
    { accessorKey: 'overture_tld', header: 'Overture TLD', size: 100 },
    { accessorKey: 'overture_sld', header: 'Overture SLD', size: 100 },
    { accessorKey: 'whois_registrar', header: 'Registrar', size: 100 },
    { accessorKey: 'whois_expire_date', header: 'Expire Date', size: 100 },
    { accessorKey: 'whois_update_date', header: 'Update Date', size: 100 },
    { accessorKey: 'whois_ns1', header: 'NS1', size: 100 },
    { accessorKey: 'whois_ns2', header: 'NS2', size: 100 },
    { accessorKey: 'whois_age', header: 'Whois Age', size: 100 },
    { accessorKey: 'drop_date', header: 'Drop Date', size: 100 },
    { accessorKey: 'auction_price', header: 'Auction Price', size: 100 },
    { accessorKey: 'auction_bidders', header: 'Auction Bidders', size: 100 },
    { accessorKey: 'auction_join_date', header: 'Auction Join Date', size: 100 },
    { accessorKey: 'auction_end_date', header: 'Auction End Date', size: 100 },
    { accessorKey: 'auction_url', header: 'Auction URL', size: 100 },
    { accessorKey: 'auction_traffic', header: 'Auction Traffic', size: 100 },
    { accessorKey: 'auction_revenue', header: 'Auction Revenue', size: 100 },
    { accessorKey: 'auction_title', header: 'Auction Title', size: 100 },
    { accessorKey: 'auction_description', header: 'Auction Desc', size: 100 },
    { accessorKey: 'adult', header: 'Adult', size: 60 },
    { accessorKey: 'yahoo', header: 'Yahoo', size: 60 },
    { accessorKey: 'trademark_type', header: 'TM Type', size: 100 },
    { accessorKey: 'trademark_term', header: 'TM Term', size: 100 },
    { accessorKey: 'trademark_company', header: 'TM Company', size: 100 },
    { accessorKey: 'trademark_risk', header: 'TM Risk', size: 100 },
    { accessorKey: 'trademark_probability', header: 'TM Prob', size: 100 },
    { accessorKey: 'has_num', header: 'Has Num', size: 100 },
    { accessorKey: 'has_dash', header: 'Has Dash', size: 100 },
    { accessorKey: 'extensions_taken', header: 'Ext Taken', size: 100 },
    { accessorKey: 'traffic_estimate', header: 'Traffic Est', size: 100 },
    { accessorKey: 'parking_revenue_estimate', header: 'Parking Rev Est', size: 100 },
    { accessorKey: 'development_revenue_estimate', header: 'Dev Rev Est', size: 100 },
    { 
      accessorKey: 'process_date', 
      header: 'Process Date', 
      size: 120,
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: true,
    enableStickyHeader: true,

    initialState: {
      columnVisibility: hiddenColumns,
      density: 'compact',
      pagination: { pageSize: 200, pageIndex: 0 },
    },

    muiPaginationProps: {
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      showFirstButton: false,
      showLastButton: false,
    },

    manualPagination: true,
    rowCount: totalElements,
    onPaginationChange: setPagination,
    state: {
      pagination,
      isLoading: loading,
    },

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },

    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#FFC0CB',
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Analysis Hub
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <TextField
            label="Search by Date"
            type="date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            size="small"
          />
        </Stack>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab 
                  key={listType} 
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <MaterialReactTable table={table} />
            </TabPanel>
          ))}
        </TabContext>
        <Snackbar 
          open={success} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Operation successful!
          </Alert>
        </Snackbar>
        <Snackbar 
          open={error} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setError(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Operation failed!
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}

const getTooltipTitle = (rating) => {
  switch (rating) {
    case 1: return 'Specific';
    case 2: return 'Generic';
    case 3: return 'Venture';
    default: return '';
  }
};


const StarRatingButton = React.memo(({ rowId,domain, acquShortlistedDomain, userEmail, onShortlistChange, onShortlistChangeAfterAPI }) => {
  const [rating, setRating] = useState(0);
  const [errorDomain, setErrorDomain] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(false);
    if (acquShortlistedDomain && acquShortlistedDomain.aqShortlistings) {
      // console.log("rowId", rowId);
      // console.log("acquShortlistedDomain", acquShortlistedDomain);
      // console.log("userEmail", userEmail);
      const userShortlisting = acquShortlistedDomain.aqShortlistings.find(
        shortlisting => shortlisting.user.email === userEmail
      );
      if (userShortlisting) {
        switch (userShortlisting.domainType) {
          case 's': setRating(1); break;
          case 'g': setRating(2); break;
          case 'v': setRating(3); break;
          default: setRating(0);
        }
      } else {
        setRating(0);
      }
    } else {
      setRating(0);
    }
  }, [acquShortlistedDomain, userEmail]);

  const getStarColor = () => {
    switch (rating) {
      case 1: return 'gold';
      case 2: return '#0ab4f7';
      case 3: return '#1fcc41';
      default: return 'inherit';
    }
  };

 
  const handleClick = () => {
    setIsLoading(true);
    const oldRating = rating;
    const newRating = (rating + 1) % 4;

    
    onShortlistChange(rowId, newRating);
    //setRating(newRating);
    // shortlistAnalysisHub(rowId, newRating)
    //   .then(updatedRow => {
    //     onShortlistChange(updatedRow);
    //   }).catch(error => {
    //     console.error('Error updating shortlist:', error);
    //     setError(true);
    //     setErrorDomain(domain);
    //     setRating(oldRating);
    //   });
  };

  return (
    <Box>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
          Error in shortlisting domain: {domain}
        </Alert>
        </Snackbar>
        <Tooltip title={getTooltipTitle(rating)} placement="left">
      <IconButton disabled={isLoading} onClick={handleClick} size="small">
        {rating === 0 ? (
        <StarBorder fontSize="small" />
      ) : (
        <Star fontSize="small" sx={{ color: getStarColor() }} />
        )}
    </IconButton>
    </Tooltip>
    </Box>
  );
});