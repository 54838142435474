import { Stack, ThemeProvider } from "@mui/material";
import { theme } from "../Theme";
import React, { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { getEmailCollectedData } from "../api";

export default function EmailDone() {
  const [completedWork, setCompletedWork] = useState([]);

  const columns = [
    {
      id: 'domain',
      header: 'Domain',
      accessorKey: 'domain',
    },
    {
      id: 'emailsFound',
      header: 'Emails Found',
      accessorFn: (row) => row.emails?.length || 0,
    },
    {
      id: 'completionDate',
      header: 'Completed On',
      accessorFn: (row) => new Date(row.completedAt).toLocaleDateString(),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable 
        columns={columns}
        data={completedWork}
      />
    </ThemeProvider>
  );
}