import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography } from "@mui/material";
import { getUserLocation } from "../Components/api"

export default function UserLocationList() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await getUserLocation();
        setData(response.data);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: "userId",
                header: "User ID",
                size: 200
            },
            {
                accessorKey: "latitude",
                header: "Latitude",
            },
            {
                accessorKey: "longitude",
                header: "Longitude",
            },
            {
                accessorKey: "address",
                header: "Address",
                size: 350, // Set the width of the address column to be twice the size of others
            },
            {
                accessorKey: "timestamp",
                header: "Timestamp",
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
            },
        ],
        []
    );

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Location Log
                </Typography>
            </Box>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableSorting
                enableColumnResizing
                enableRowSelection
                initialState={{
                    columnVisibility: {
                        id: false, // Hide the ID column
                    },
                }}
            />
        </Box>
    );
}
