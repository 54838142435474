import { Alert, Box, Button, Dialog, DialogActions, Tab, Tabs, Chip, OutlinedInput, Checkbox, ListItemText, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography, Tooltip, InputAdornment, TextField } from "@mui/material";
import { theme } from '../Theme';
import React, { useEffect, useMemo, useState, useCallback } from "react";
import UploadCSVDialog from "../UploadCSVDialog";
import UploadCSVDialogAldDomains from "../Common/UploadCSVDialogAldDomains";
import { msalInstance, isAdmin, canAddAld } from '../msalService';
import { Add, Edit, PanToolAltOutlined, Person, Groups } from "@mui/icons-material";
import {
  getAllAldDomainsWithPagination,
  editAldDomains,
  uploadAldDomainss,
  getAllTags,
  allocateDomainToTeams,
  allocateDomainToProcessors,
  getAllPortfolioDomains,
} from '../api';
import { useUser } from '../UserProvider';
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Sort as SortIcon } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import SearchIcon from '@mui/icons-material/Search';
import AllocationDialog from '../Common/AllocationDialog';
import OverwriteDialog from '../Common/OverwriteDialog';

export default function MyDomainsALD() {

  const [domains, setDomains] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [data, setData] = useState([]);
  const [editDomains, setEditDomains] = useState([]);
  const [tags, setTags] = useState([]);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  let [idds, setIdds] = React.useState([]);
  const { user, updateUser } = useUser();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50, });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClose1 = () => {
    setOpenDialog1(false);
  };

  function formatDateToYYYYMMDD(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    try {
      getAllPortfolioDomains({
        page: paginationRules.pageIndex,
        size: paginationRules.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: searchQuery,
        portfolio: "ald"
      })
        .then(response => {
          if (response && response.data) {
            setData(response.data.content);
            setTotalRowCount(response.data.totalElements);
          } else {
            console.error('Invalid API response format:', response);
            setData([]);
            setTotalRowCount(0);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalRowCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchData:', error);
      setLoading(false);
      setData([]);
      setTotalRowCount(0);
    }
  }, [columnFilters, sorting, pagination, globalSearch]);

  // Add handlers for filter and sort dialogs
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  useEffect(() => {
    console.log(editDomains);
    if (editDomains && editDomains.length > 0)
      editAldDomains(editDomains)
        .then((res) => {
          console.log(res);
          setUpsuc(true);
          setEditDomains([]);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [editDomains]);

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]);

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
    setExistingDomains([]);
    setSelectedExistingDomains([]);
    setOpenExistingDomainsDialog(false);
  };

  const handleCloseExistingDomainsDialog = () => {
    setOpenExistingDomainsDialog(false);
    setSelectedExistingDomains([]);
    setExistingDomains([]);
    setDomains([]);
    if (idds.length > 0) {
      setOpenAllocate(true);
    }
  };

  const handleSelectAll = (event) => {
    setSelectedExistingDomains(event.target.checked ? existingDomains : []);
  };

  const handleExistingDomainSelect = (event) => {
    const { value } = event.target;
    setSelectedExistingDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
  };

  const handleUploadDomains = async (domains, formattedDate = '', overwrite = false) => {
    try {
      const res = await uploadAldDomainss(domains, formattedDate, overwrite);
      const newIds = res.data.savedDomainIds;
      setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));

      if (res.data.existingDomains.length > 0) {
        setExistingDomains(res.data.existingDomains);
        setOpenExistingDomainsDialog(true);
      } else {
        setOpenAllocate(true);
        setUpsuc(true);
        setDomains([]);
        handleCloseExistingDomainsDialog();
      }
      fetchData();
    } catch (err) {
      console.log(err);
      setDomains([]);
      setUpfail(true);
    }
  };

  useEffect(() => {
    if (domains && domains.length > 0) {
      handleUploadDomains(domains, formatDateToYYYYMMDD(selectedDate));
    }
  }, [domains]);

  useEffect(() => {
    getAllTags().then((res) => {
      const arr = res.data.map((ar) => ar.tag); console.log(arr); setTags(arr)
    }).catch((err) => { console.log(err) })
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'platform',
        id: 'platform',
        header: 'Platform',
      }, {
        accessorKey: 'domain',
        id: 'domain',
        header: 'Domain',
      }, {
        id: 'dateOfUpload',
        header: 'Add Date',
        accessorFn: (originalRow) => { const date = new Date(originalRow.dateOfUpload); return date },
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
      }, {
        id: 'allocatedTo',
        header: 'Allocated To',
        Cell: ({ row }) => {
          const allocation = row.original.presentAllocation;
          const teamAllocation = row.original.presentTeamAllocations?.[0];

          if (allocation?.user) {
            // Individual processor allocation
            return (
              <Tooltip
                title={`Processor: ${allocation.user.firstName} ${allocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Person
                    sx={{
                      color: '#FFA726', // Orange color for processor
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`${allocation.user.firstName} ${allocation.user.lastName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else if (teamAllocation?.user) {
            // Team allocation
            return (
              <Tooltip
                title={`Team: ${teamAllocation.user.firstName} ${teamAllocation.user.lastName}`}
                placement="bottom"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Groups
                    sx={{
                      color: '#42A5F5', // Blue color for team
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic'
                    }}
                  >
                    {`Team ${teamAllocation.user.firstName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else {
            // Not allocated
            return (
              <Tooltip title="Not Allocated" placement="bottom">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Person
                    sx={{
                      color: '#9E9E9E', // Gray color for unallocated
                      fontSize: '1.2rem'
                    }}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.7rem',
                      fontStyle: 'italic',
                      color: '#9E9E9E'
                    }}
                  >
                    Unallocated
                  </Typography>
                </Box>
              </Tooltip>
            );
          }
        }
      }, {
        id: 'orderDate',
        header: 'Order Date',
        accessorFn: (originalRow) => { const date = new Date(originalRow.orderDate); return date },
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],

      }, {
        accessorKey: 'orderStatus',
        id: 'orderStatus',
        header: 'Order Status',

      }, {
        accessorKey: 'EST',
        id: 'EST',
        header: 'EST',
      }, {
        accessorKey: 'GDV',
        header: 'GDV',
      }, {
        accessorKey: 'currentPrice',
        id: 'currentPrice',
        header: 'Current Price',

      }, {
        accessorKey: 'reserve',
        id: 'reserve',
        header: 'Reserve',

      }, {
        accessorKey: 'clientOffer',
        id: 'clientOffer',
        header: 'Client Offer',

      }, {
        id: 'type',
        header: 'Type',
        accessorFn: (row) => { if (row.brandable) return 'Brandable'; else return 'Generic'; },

      }, {
        accessorKey: 'categoryMain',
        id: 'categoryMain',
        header: 'Category',

      }, {
        accessorKey: 'categorySedo',
        id: 'categorySedo',
        header: 'SEDO Category',

      }, {
        accessorKey: 'categoryDan',
        id: 'categoryDan',
        header: 'DAN Category',

      }, {
        accessorKey: 'tags',
        enableColumnFilterModes: false,
        filterVariant: 'multi-select',
        filterFn: (row, id, filterValue) => {
          if (filterValue.length == 0) return true; else { for (let i = 0; i < row.original.tags.length; i++) { if (filterValue.includes(row.original.tags[i].tag)) return true; } return false; }//return row.tags[0].tag === filterValue;
        },
        filterSelectOptions: tags,
        header: 'Tags',
        Cell: ({ cell }) => { if (cell.getValue().length > 0) return cell.getValue()[0].tag; }, //format date for display

      }, {
        id: 'registrar',
        header: 'Registrar',
        accessorFn: (row) => { if (row.registrar) return row.registrar.registrar; else return 'N/A' },

      }, {
        accessorKey: 'whoIsEmail',
        id: 'whoIsEmail',
        header: 'WhoIs Email',

      }, {
        accessorKey: 'nameservers',
        id: 'nameservers',
        header: 'Nameservers',

      }, {
        accessorKey: 'wby',
        header: 'WBY',

      }, {
        accessorKey: 'aby',
        header: 'ABY',

      }, {
        accessorKey: 'reg',
        header: 'Reg',

      }, {
        accessorKey: 'regx',
        header: 'Regx',
      }, {
        accessorKey: 'sg',
        header: 'SG',
      }, {
        accessorKey: 'cpc',
        header: 'CPC',
      }
    ],
    [tags]
  );

  const domainTable = useMaterialReactTable({
    columns: columns,
    data: data,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    // enableFilters: false,
    enableFilters: false, // Disables the filter icon
    enableGlobalFilter: true, // Keeps the global search active
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,

    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Sort
        </Button>
        {isAdmin() && (
          <Button
            disabled={table.getSelectedRowModel().rows.length === 0}
            variant="outlined"
            startIcon={<PanToolAltOutlined />}
            onClick={() => {
              setOpenAllocate(true);
              let id = table.getSelectedRowModel().rows.map((row) => row.original.id);
              setIdds(id);
            }}
            sx={{
              textTransform: 'none',
              backgroundColor: '#8797A8',
              color: '#FFFFFF !important',
              padding: '4px 8px',
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: '#8797A8',
                opacity: 0.9,
              },
              '&.Mui-disabled': {
                backgroundColor: '#8797A8',
                opacity: 0.7,
              }
            }}
          >
            Allocate
          </Button>
        )}
        <Box flexGrow={1} />
        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          size="small"
          sx={{
            minWidth: '100px',
            '& .MuiOutlinedInput-root': {
              height: '30px',
              fontSize: '0.875rem',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#8797A8',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '20px',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{ fontSize: '1.2rem', color: '#8797A8', cursor: 'pointer' }}
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 }
    },

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });


  return (<ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          My Domains
        </Typography>
        <Typography
          variant="h6"
          color='gray'
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          ALD
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        {(isAdmin() || canAddAld()) &&
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => { setOpenDialog(true); }}
              sx={{
                height: 40,
                borderRadius: 100,
                textTransform: 'none'
              }}
            >
              Add Domains
            </Button>
            <UploadCSVDialogAldDomains title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              onClick={() => { setOpenDialog1(true); }}
              sx={{
                height: 40,
                borderRadius: 100,
                textTransform: 'none'
              }}
            >
              Edit Domains
            </Button>
            <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} />
          </>}

      </Stack>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AllocationDialog 
            open={openAllocate}
            onClose={handleCloseAllocate}
            selectedIds={idds}
            onSuccess={() => {
              setUpsuc1(true);
              fetchData();
            }}
            onError={() => setUpfail1(true)}
            onComplete={() => {
              setIdds([]);
            }}
          />

          <OverwriteDialog
            open={openExistingDomainsDialog}
            onClose={handleCloseExistingDomainsDialog}
            existingDomains={existingDomains}
            selectedDomains={selectedExistingDomains}
            onSelectAll={handleSelectAll}
            onDomainSelect={handleExistingDomainSelect}
            onOverwrite={() => {
              const domainsWithDates = selectedExistingDomains.map(selectedDomain => {
                const originalDomain = domains.find(d => d.domain === selectedDomain);
                return {
                  domain: selectedDomain,
                  orderdate: originalDomain?.orderdate
                };
              });
              handleUploadDomains(domainsWithDates, true);
            }}
          />
          
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <MaterialReactTable table={domainTable} />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}