import { Alert, Box, Button, IconButton, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography, MenuItem, Select, Checkbox, FormControlLabel } from "@mui/material";
import { theme } from "./Theme";
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getSocialAllocationsWork, setSocialAllocationsWork } from "./api";
import { Add, AddCircle, Facebook, Instagram, LinkedIn, Remove, RemoveCircle, Twitter, Upload, X } from "@mui/icons-material";
import { openDB } from 'idb';

// Initialize IndexedDB
const initDB = async () => {
  return openDB('socialWorkStationDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('allocations')) {
        db.createObjectStore('allocations', { keyPath: 'id', autoIncrement: true });
      }
    },
  });
};

// Save data to IndexedDB
const saveToDB = async (data) => {
  const db = await initDB();
  const tx = db.transaction('allocations', 'readwrite');
  const store = tx.objectStore('allocations');
  await store.clear();
  await Promise.all(data.map(item => store.put(item)));
  await tx.done;
};

// Load data from IndexedDB
const loadFromDB = async () => {
  const db = await initDB();
  const tx = db.transaction('allocations', 'readonly');
  const store = tx.objectStore('allocations');
  const allData = await store.getAll();
  await tx.done;
  return allData;
};

let createLeadRow = () => {
  return {
    domain: "",
    description: "",
    copyright: "",
    priorityOrder: "",
    isInfluencer: false,
    noSocialsAvailable: false,
    siteStatus: "active",
    socials: [{ platform: "Linkedin", url: "", followers: "", employees: "", status: "", username: "" }]
  }
}

const convertToInteger = (inputText) => {
  // Trim the input to remove extra spaces
  const trimmedInput = inputText.trim();
  if (trimmedInput.length == 0)
    return 0;
  if (trimmedInput.toLowerCase().endsWith('k')) {
    const numberPart = parseFloat(trimmedInput.slice(0, -1));
    return Math.round(numberPart * 1000);
  } else {
    return parseInt(trimmedInput.replace(/,/g, ''), 10);
  }
};


let createCSocialsRow = () => {
  return { platform: "Linkedin", url: "", followers: "", employees: "", status: "" }
}

export default function SocialWorkStation() {
  const [presentAllocs, setPresentAllocs] = useState([]);
  let num = useRef(0);
  const [den, setDen] = useState(0);
  const [openS, setOpenS] = useState(false);
  const [openF, setOpenF] = useState(false);
  const [b, setB] = useState(false);
  const columns = React.useMemo(
    () => [
      {
        id: 'domain',
        header: 'Domain',
        accessorFn: (row) => { return row.presentDomain.domain; },
        size: 160,
      },
      {
        id: 'deadline',
        accessorFn: (row) => { return (new Date(row.presentDomain.deadline)).toISOString().split('T')[0] },
        header: 'Deadline',
        size: 80,
      },
      {
        id: 'edate',
        accessorFn: (row) => { return row.presentDomain.edate ? (new Date(row.presentDomain.edate)).toISOString().split('T')[0] : "N/A" },
        header: 'E-Date',
        size: 80,
      },
      {
        id: 'noLeadsAvailable',
        header: 'NL',
        size: 15,
        Cell: ({ row }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={row.original.socialWork.noLeadsAvailable || false}
                size="small"
                onChange={(event) => {
                  setPresentAllocs((prevAllocs) => {
                    const updatedAllocs = [...prevAllocs];
                    updatedAllocs[row.index].socialWork.noLeadsAvailable = event.target.checked;
                    return updatedAllocs;
                  });
                }}
                sx={{
                  padding: '2px',
                  '& .MuiSvgIcon-root': { fontSize: 18 }
                }}
              />
            }
            sx={{
              margin: 0,
              height: 25,
              '& .MuiFormControlLabel-label': { marginLeft: '2px' }
            }}
          />
        ),
      },
      {
        id: 'add',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Box width='100%' sx={{
              opacity: row.original.socialWork.noLeadsAvailable ? 0.5 : 1,
              pointerEvents: row.original.socialWork.noLeadsAvailable ? 'none' : 'auto'
            }}>
              <AddLeadButton
                addLead={addLead}
                i1={row.index}
                disabled={row.original.socialWork.noLeadsAvailable}
              />
            </Box>
          )
        },
        header: 'Add',
        size: 50,
      },
      {
        id: 'leads',
        accessorFn: (row) => { return row.socialWork.topLeads; },
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Box sx={{
              opacity: row.original.socialWork.noLeadsAvailable ? 0.5 : 1,
              pointerEvents: row.original.socialWork.noLeadsAvailable ? 'none' : 'auto'
            }}>
              <LeadSection
                row={row}
                addRowC={addRowC}
                subRowC={subRowC}
                setLeadName={setLeadName}
                addLead={addLead}
                setCp={setCp}
                setDesc={setDesc}
                setLeadStatus={setLeadStatus}
                setIsInfluencer={setIsInfluencer}
                setNoSocials={setNoSocials}
                addSocialField={addSocialField}
                subRowL={subRowL}
                setPriority={setPriority}
                disabled={row.original.socialWork.noLeadsAvailable}
              />
            </Box>
          )
        },
        header: 'Leads',
        size: 1070,
      }
    ],
    []
  );

  const domainTable = useMaterialReactTable({
    columns: columns,
    data: presentAllocs,
    layoutMode: 'semantic',
    initialState: {
      density: 'compact',
      columnVisibility: {
        deadline: false,
        edate: false
      }
    },
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' width='100%' alignItems='center'>
        <Button
          variant="text"
          startIcon={<Upload />}
          disabled={table.getSelectedRowModel().rows.length == 0}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            marginRight: '8px',
            '& .MuiButton-startIcon': {
              marginRight: '4px',
            },
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
              boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        {table.getSelectedRowModel().rows.length > 0 && (
          <Typography variant='body2' fontWeight='bold'>
            {table.getSelectedRowModel().rows.length} of {presentAllocs.length} domains selected.
          </Typography>
        )}
        <Box flexGrow={1} />
      </Stack>
    ),
    muiTablePaperProps: ({ table }) => ({
      elevation: 0,
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    }),
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTableBodyProps: {
      sx: {
        backgroundColor: 'whitesmoke',
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#FFC0CB',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.8rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
  });

  useEffect(() => {
    console.log(presentAllocs);
    console.log("Selected Model", domainTable.getSelectedRowModel().rows);
  }, [presentAllocs]);

  ///////////////////////////
  useEffect(() => {
    updateDataFromBackend();
  }, []);

  const updateAllocations = (updatedAllocs) => {
    setPresentAllocs(updatedAllocs);
    saveToDB(updatedAllocs); // Save changes to IndexedDB
  };

  const addRowC = useCallback((i1, i2) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].socials = [...updatedAllocs[i1].socialWork.topLeads[i2].socials, createCSocialsRow()];
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const subRowC = useCallback((i1, i2, i3) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].socials = [
        ...updatedAllocs[i1].socialWork.topLeads[i2].socials.slice(0, i3),
        ...updatedAllocs[i1].socialWork.topLeads[i2].socials.slice(i3 + 1)
      ];
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const subRowL = useCallback((i1, i2) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads = [
        ...updatedAllocs[i1].socialWork.topLeads.slice(0, i2),
        ...updatedAllocs[i1].socialWork.topLeads.slice(i2 + 1)
      ];
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const setCp = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].copyright = c;
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, [])

  const setDesc = useCallback((i1, i2, c) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].description = c;
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, [])

  const addSocialField = useCallback((i1, i2, i3, c, val) => {
    setPresentAllocs((prevAllocs) => {
      console.log("Add Social Field", val, c)
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].socials[i3][c] = val;
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, [])

  const setLeadName = useCallback((i1, i2, inputValue) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].domain = inputValue;
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, [])

  const addLead = useCallback((i1) => {
    setPresentAllocs((prevAllocs) => {
      let updatedAllocs = [...prevAllocs];
      updatedAllocs[i1] = { ...updatedAllocs[i1], socialWork: { ...updatedAllocs[i1].socialWork, topLeads: [...updatedAllocs[i1].socialWork.topLeads, createLeadRow()] } };
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, [])

  const setPriority = useCallback((i1, i2, value) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      updatedAllocs[i1].socialWork.topLeads[i2].priorityOrder = value;
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const setLeadStatus = useCallback((i1, i2, status) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      if (updatedAllocs[i1] && updatedAllocs[i1].socialWork && updatedAllocs[i1].socialWork.topLeads[i2]) {
        updatedAllocs[i1].socialWork.topLeads[i2].leadStatus = status;
      } else {
        console.error(`Invalid indices or uninitialized data: i1=${i1}, i2=${i2}`);
      }
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const setIsInfluencer = useCallback((i1, i2, isInfluencer) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      if (updatedAllocs[i1] && updatedAllocs[i1].socialWork && updatedAllocs[i1].socialWork.topLeads[i2]) {
        updatedAllocs[i1].socialWork.topLeads[i2].isInfluencer = isInfluencer;
      } else {
        console.error(`Invalid indices or uninitialized data: i1=${i1}, i2=${i2}`);
      }
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const setNoSocials = useCallback((i1, i2, noSocials) => {
    setPresentAllocs((prevAllocs) => {
      const updatedAllocs = [...prevAllocs];
      if (updatedAllocs[i1] && updatedAllocs[i1].socialWork && updatedAllocs[i1].socialWork.topLeads[i2]) {
        updatedAllocs[i1].socialWork.topLeads[i2].noSocialsAvailable = noSocials;
      } else {
        console.error(`Invalid indices or uninitialized socials: i1=${i1}, i2=${i2}`);
      }
      saveToDB(updatedAllocs); // Save changes to IndexedDB
      return updatedAllocs;
    });
  }, []);

  const updateDataFromBackend = async () => {
    try {
      const freshData = await getSocialAllocationsWork();
      
      const freshAllocations = freshData.data.map(allocation => {
        allocation.socialWork = allocation.socialWork || { topLeads: [] };
        allocation.socialWork.topLeads = allocation.socialWork.topLeads.map(lead => ({
          ...lead,
          socials: lead.socials || [createCSocialsRow()],
          isInfluencer: lead.isInfluencer || false,
          leadStatus: lead.leadStatus || 'active'
        }));
        return allocation;
      });

      // Load current allocations from IndexedDB
      const currentAllocs = await loadFromDB();

      // Identify entries to remove and add
      const currentIds = new Set(currentAllocs.map(alloc => alloc.id));
      const freshIds = new Set(freshAllocations.map(alloc => alloc.id));

      const toRemove = currentAllocs.filter(alloc => !freshIds.has(alloc.id));
      const toAdd = freshAllocations.filter(alloc => !currentIds.has(alloc.id));

      // Combine current allocations and new additions
      const updatedAllocs = [
        ...currentAllocs.filter(alloc => freshIds.has(alloc.id)),
        ...toAdd
      ];

      // Update state with the combined allocations
      setPresentAllocs(updatedAllocs);

      // Update IndexedDB asynchronously
      const db = await initDB();
      const tx = db.transaction('allocations', 'readwrite');
      const store = tx.objectStore('allocations');

      // Remove entries not in freshAllocations
      await Promise.all(toRemove.map(alloc => store.delete(alloc.id)));

      // Add new entries from freshAllocations
      await Promise.all(toAdd.map(alloc => store.put(alloc)));

      await tx.done;
      domainTable.resetRowSelection();
    } catch (err) {
      console.log("Error fetching fresh data:", err);
    }
  };

  const handleSubmit = async () => {
    const selectedRows = domainTable.getSelectedRowModel().rows.map(row => row.original);
    setDen(selectedRows.length);
    try {
      const res = await setSocialAllocationsWork(selectedRows);
      num.current = res.data;
      if (res.data > 0) {
        setOpenS(true);
        setB(!b);

        // Remove submitted rows from state and IndexedDB
        setPresentAllocs(prevAllocs => {
          const remainingAllocs = prevAllocs.filter(alloc => !selectedRows.includes(alloc));
          saveToDB(remainingAllocs); // Save remaining allocations to IndexedDB
          return remainingAllocs;
        });

        updateDataFromBackend();
      } else {
        setOpenF(true);
      }
    } catch (err) {
      console.log("Error submitting data:", err);
      setOpenF(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5} width='100%'>
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}>
            Leads Hub
          </Typography>
        </Stack>
        <Snackbar open={openS} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenS(false); }}>
          <Alert severity='success' sx={{ width: '100%' }}>
            {num.current} out of {den} submitted!
          </Alert>
        </Snackbar>
        <Snackbar open={openF} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenF(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Submitted!
          </Alert>
        </Snackbar>
        <Box>
          <MaterialReactTable table={domainTable} />
        </Box>
      </Stack>
    </ThemeProvider>
  )
}

//===================================================================================================================
const AddLeadButton = memo(({ addLead, i1, disabled }) => {
  return (
    <IconButton
      size="small"
      color="primary"
      onClick={() => addLead(i1)}
      disabled={disabled}
    >
      <AddCircle fontSize="small" />
    </IconButton>
  );
});

//===================================================================================================================
const CopyRighttb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value) }, [props.value])
  const handleInputChange = (event) => {
    const val = event.target.value
    if (/^\d*$/.test(val)) {
      setInputValue(val);
    }
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.setCp(props.i1, props.in, inputValue)
    }
  };
  return (
    <TextField
      placeholder="Copyright"
      value={inputValue ? inputValue : ""}
      size='small'
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.setCp(props.i1, props.in, inputValue);
      }}
      onKeyPress={handleInputKeyPress}
      variant='outlined'
      margin="normal"
      autoCorrect={false}
      InputProps={{ sx: { fontSize: 12, height: 28 } }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 }, padding: 0, margin: 0
      }}
    />
  )
})

//===================================================================================================================
const Desctb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value) }, [props.value])
  const handleInputChange = (event) => {
    const val = event.target.value
    setInputValue(val);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.setDesc(props.i1, props.in, inputValue)
    }
  };
  return (<TextField
    placeholder="Description"
    value={inputValue ? inputValue : ""}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={() => {
      props.setDesc(props.i1, props.in, inputValue);
    }}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{ sx: { fontSize: 12, height: 28 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

//===================================================================================================================
const Leadtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => {
    console.log("Leadtb ", props.i1, props.value)
    setInputValue(props.value)
  }, [props.value])
  const handleInputChange = (event) => {
    const val = event.target.value
    setInputValue(val);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.setLeadName(props.i1, props.in, inputValue)

    }
  };
  return (<TextField
    placeholder="lead.com"
    value={inputValue ? inputValue : ""}
    size='small'
    onChange={handleInputChange}
    onBlurCapture={() => {
      props.setLeadName(props.i1, props.in, inputValue);
    }}
    onKeyPress={handleInputKeyPress}
    variant='outlined'
    margin="normal"
    autoCorrect={false}
    InputProps={{ sx: { fontSize: 12, height: 28 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0
    }}
  />)
})

//===================================================================================================================
const RowActionsL = memo(({ i1, i2, l, subRowL }) => {
  return (<Box sx={{ display: 'flex', gap: '1rem' }}>
    {(!(i2 == 0 && l == 1)) &&
      <Tooltip title="Remove">
        <IconButton size="small" sx={{ padding: 0 }} color="primary" onClick={() => {
          subRowL(i1, i2); const a = "ab";
        }}>
          <RemoveCircle fontSize="small" sx={{ padding: 0 }} />
        </IconButton>
      </Tooltip>}
  </Box>);
})

//===================================================================================================================
const LeadSection =
  memo(({ row: parentRow, subRowC, addRowC, setLeadName, setCp, setDesc, setLeadStatus, setIsInfluencer, setNoSocials, addSocialField, subRowL}) => {
    const leadsData = useMemo(() => { console.log("Leads Data", parentRow.original.socialWork.topLeads); return parentRow.original.socialWork.topLeads }, [parentRow.original.socialWork.topLeads]);
    const [r1, setR1] = useState(parentRow);
    const leadsColumns = useMemo(() => [
      {
        id: 'domain',
        accessorKey: 'domain',
        header: 'Leads',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Stack direction="column" spacing={1}>
              <Leadtb
                value={row.original.domain}
                in={row.index}
                i1={r1.index}
                setLeadName={setLeadName}
              />
              <CopyRighttb
                value={row.original.copyright}
                in={row.index}
                i1={r1.index}
                setCp={setCp}
              />
            </Stack>
          );
        },
        size: 200,

      },
      {
        header: 'Description',
        id: 'description',
        size: 200,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Stack
              direction="column"
              spacing={1}
              sx={{
                width: '100%',
              }}
            >
              <Desctb
                value={row.original.description}
                in={row.index}
                i1={r1.index}
                setDesc={setDesc}
              />
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  height: 26,
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <Stack direction="row" spacing={0.5}>
                  <NoSocialsCheckbox
                    checked={parentRow.original.noSocialsAvailable}
                    i1={r1.index}
                    in={row.index}
                    setNoSocials={setNoSocials}
                  />
                  <InfluencerCheckbox
                    checked={parentRow.original.isInfluencer}
                    i1={r1.index}
                    in={row.index}
                    setIsInfluencer={setIsInfluencer}
                  />
                </Stack>
                <LeadStatusDropdown
                  value={parentRow.original.siteStatus}
                  i1={r1.index}
                  in={row.index}
                  setLeadStatus={setLeadStatus}
                />
              </Stack>
            </Stack>
          );
        },
      },
      {
        header: 'Socials',
        id: 'socials',
        Cell: ({ renderedCellValue, row }) => {
          return (
            <SocialsSection
              row={row}
              socialsData={row.original.socials}
              r1={r1}
              subRowC={subRowC}
              addRowC={addRowC}
              addSocialField={addSocialField}
            />
          );
        },
        size: 620,
      }
    ], [parentRow.original.socialWork.topLeads]);


    const [isLoading, setIsLoading] = useState(false);

    const leadTable = useMaterialReactTable({
      columns: leadsColumns,
      data: leadsData,
      enableExpandAll: true,
      enableBottomToolbar: false,
      enableTopToolbar: false,
      enableTableHead: false,
      layoutMode: 'grid-no-grow',
      enableRowActions: true,
      positionActionsColumn: 'last',
      renderRowActions: ({ row }) => (
        <RowActionsL i1={r1.index} i2={row.index} l={leadsData.length} subRowL={subRowL} site={row.original.site} />
      ),
      initialState: {
        density: 'compact'
      },
      muiTableBodyProps: { 
        sx: { 
          backgroundColor: 'transparent',
        } 
      },
      muiTableBodyRowProps: { 
        sx: { 
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(227, 242, 253, 0.7) !important',
          },
        } 
      },
      muiTablePaperProps: { 
        sx: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
        } 
      },
      muiTableProps: {
        sx: {
          boxShadow: 'none',
          borderSpacing: '0',
          '& .MuiTableCell-root': {
            border: 'none',
          },
        }
      },
      muiTableBodyCellProps: {
        sx: {
          border: 'none',
          borderBottom: 'none',
        }
      },
    });
    return <Stack direction='column' spacing={1}>
      <MaterialReactTable table={leadTable} />
    </Stack>;
  })

//===================================================================================================================
const conttbvariant = 'outlined'
const Socialstb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    props.addSocialField(props.i1, props.i2, props.i3, props.c, newValue);
  };

  return (
    <Select
      value={inputValue}
      onChange={handleInputChange}
      size="small"
      variant={conttbvariant}
      displayEmpty
      sx={{
        fontSize: 12,
        height: 25,
        width: '100%',
        '& .MuiSelect-select': {
          padding: '2px 10px 2px 8px',
          height: '21px',
          lineHeight: '21px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      <MenuItem value="" disabled style={{ fontSize: 12, height: 25 }}><em>Status</em></MenuItem>
      <MenuItem value="active" style={{ fontSize: 12, height: 25 }}>Active</MenuItem>
      <MenuItem value="inactive" style={{ fontSize: 12, height: 25 }}>Inactive</MenuItem>
      <MenuItem value="zero" style={{ fontSize: 12, height: 25 }}>Zero</MenuItem>
    </Select>
  );
});

const Followerstb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value); }, [props.value])
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      let i = convertToInteger(inputValue);
      props.addSocialField(props.i1, props.i2, props.i3, props.c, i);
    }
  };
  return (<TextField
    placeholder={props.c}
    value={inputValue}
    size='small'
    type='number'
    onChange={handleInputChange}
    onBlurCapture={() => {
      let i = convertToInteger(inputValue);
      props.addSocialField(props.i1, props.i2, props.i3, props.c, i);
    }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    InputProps={{ sx: { fontSize: 12, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0,
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
      "& input[type=number]": { MozAppearance: "textfield" }
    }}
  />)
})

const Employeetb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);
  useEffect(() => { setInputValue(props.value); }, [props.value])
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      let i = convertToInteger(inputValue);
      props.addSocialField(props.i1, props.i2, props.i3, props.c, i);
    }
  };
  return (<TextField
    placeholder={props.c}
    value={inputValue}
    size='small'
    type='number'
    onChange={handleInputChange}
    onBlurCapture={() => {
      let i = convertToInteger(inputValue);
      props.addSocialField(props.i1, props.i2, props.i3, props.c, i);
    }}
    onKeyPress={handleInputKeyPress}
    variant={conttbvariant}
    margin="normal"
    autoCorrect='false'
    InputProps={{ sx: { fontSize: 12, height: 25 } }}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 }, padding: 0, margin: 0,
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
      "& input[type=number]": { MozAppearance: "textfield" }
    }}
  />)
})

//===================================================================================================================
const SocialIconButton = memo(({ plat, addSocialField, i1, i2, i3, c }) => {
  const platforms = ["Linkedin", "Instagram", "Facebook", "X"];
  const icons = {
    Linkedin: <LinkedIn sx={{ color: '#0077b5' }} />,
    Instagram: <Instagram sx={{ color: '#d62976' }} />,
    Facebook: <Facebook sx={{ color: '#316FF6' }} />,
    X: <X sx={{ color: 'black' }} />
  };
  const [platform, setPlatform] = useState(plat);
  useEffect(() => { setPlatform(plat) }, [plat])

  const handleIconClick = () => {
    const currentIndex = platforms.indexOf(platform);
    const nextIndex = (currentIndex + 1) % platforms.length;
    setPlatform(platforms[nextIndex]);
    console.log("On Click", platforms[nextIndex])
    addSocialField(i1, i2, i3, c, platforms[nextIndex])
  };

  return (
    <IconButton size="small" onClick={handleIconClick}>
      {icons[platform]}
    </IconButton>
  );
});


//===================================================================================================================
const SocialsSection =
  memo(({ row: parentRow, socialsData, r1, subRowC, addRowC, addSocialField }) => {
    const [r2, setR2] = useState(parentRow);
    useEffect(() => { setR2(parentRow) }, [parentRow])
    const [sData, setSdata] = useState(socialsData);
    useEffect(() => { setSdata(socialsData) }, [socialsData])

    const isDisabled = parentRow.original.noSocialsAvailable;
    const socialsColumns = useMemo(() => [
      {
        id: 'platform',
        accessorKey: 'platform',
        size: 50,
        Cell: ({ renderedCellValue, row, column, table }) => (
          <Box width='100%'>
            <SocialIconButton
              plat={row.original.platform}
              i1={r1.index}
              i2={r2.index}
              i3={row.index}
              c={column.columnDef.accessorKey}
              addSocialField={addSocialField}
              disabled={isDisabled}
            />
          </Box>
        ),
        header: 'Platform',
      },
      {
        id: 'url',
        header: 'URL',
        size: 120,
        accessorKey: 'url',
        Cell: ({ renderedCellValue, row, column, table }) => (
          <URLtb
            value={row.original.url}
            i1={r1.index}
            i2={r2.index}
            i3={row.index}
            c={column.columnDef.accessorKey}
            addSocialField={addSocialField}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'followers',
        header: 'Followers',
        size: 80,
        accessorKey: 'followers',
        Cell: ({ renderedCellValue, row, column, table }) => (
          <Followerstb
            value={row.original.followers}
            i1={r1.index}
            i2={r2.index}
            i3={row.index}
            c={column.columnDef.accessorKey}
            addSocialField={addSocialField}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'employees',
        header: 'Employees',
        size: 80,
        accessorKey: 'employees',
        Cell: ({ renderedCellValue, row, column, table }) => (
          <Employeetb
            value={row.original.employees}
            i1={r1.index}
            i2={r2.index}
            i3={row.index}
            c={column.columnDef.accessorKey}
            addSocialField={addSocialField}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'username',
        header: 'user-name',
        size: 100,
        accessorKey: 'username',
        Cell: ({ renderedCellValue, row, column, table }) => (
          <UsernameTb
            value={row.original.username}
            i1={r1.index}
            i2={r2.index}
            i3={row.index}
            c={column.columnDef.accessorKey}
            addSocialField={addSocialField}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'status',
        accessorKey: 'status',
        size: 100,
        header: 'Status',
        Cell: ({ renderedCellValue, row, column, table }) => (
          <Socialstb
            value={row.original.status}
            i1={r1.index}
            i2={r2.index}
            i3={row.index}
            c={column.columnDef.accessorKey}
            addSocialField={addSocialField}
            disabled={isDisabled}
          />
        ),
      },
    ], [isDisabled]);

    const socialsTable = useMaterialReactTable({
      columns: socialsColumns,
      data: sData,
      enableExpandAll: true,
      enableRowActions: !isDisabled,
      positionActionsColumn: 'last',
      renderRowActions: ({ row }) => (
        <RowActionsC
          i1={r1.index}
          i2={r2.index}
          i3={row.index}
          l={sData.length}
          addRowC={addRowC}
          subRowC={subRowC}
          disabled={isDisabled}
        />
      ),
      layoutMode: 'grid-no-grow',
      enableBottomToolbar: false,
      initialState: { density: 'compact' },
      muiTableBodyProps: { 
        sx: { 
          backgroundColor: 'transparent',
        } 
      },
      muiTableBodyRowProps: ({ row }) => ({
        sx: { 
          backgroundColor: row.index % 2 === 0 
            ? 'rgba(255, 255, 255, 0.7)' 
            : 'rgba(245, 245, 245, 0.7)',
          '&:hover': {
            backgroundColor: 'rgba(227, 242, 253, 0.7) !important',
          },
          boxShadow: 'none',
        } 
      }),
      muiTablePaperProps: { 
        sx: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
        } 
      },
      muiTableProps: {
        sx: {
          boxShadow: 'none',
          borderSpacing: '0',
          '& .MuiTableCell-root': {
            border: 'none',
            padding: '1px 2px', 
          },
        }
      },
      enableTopToolbar: false,
      enableTableHead: false,
    });

    return (
      <Box flexGrow={1} width='100%' sx={{
        flexGrow: 1,
        opacity: isDisabled ? 0.5 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto'
      }}>
        {(sData && (sData && sData.length > 0)) && <MaterialReactTable table={socialsTable} />}
      </Box>
    );
  });


//===================================================================================================================
const RowActionsC = memo(({ i1, i2, i3, l, subRowC, addRowC, disabled }) => {
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Tooltip title={disabled ? "No socials available" : "Add"}>
        <span>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            color="primary"
            onClick={() => { if (!disabled) addRowC(i1, i2, i3); }}
            disabled={disabled}
          >
            <Add fontSize="small" sx={{ padding: 0 }} />
          </IconButton>
        </span>
      </Tooltip>
      {(!(i3 == 0 && l == 1)) &&
        <Tooltip title={disabled ? "No socials available" : "Remove"}>
          <span>
            <IconButton
              size="small"
              sx={{ padding: 0 }}
              color="primary"
              onClick={() => { if (!disabled) subRowC(i1, i2, i3); }}
              disabled={disabled}
            >
              <Remove fontSize="small" sx={{ padding: 0 }} />
            </IconButton>
          </span>
        </Tooltip>
      }
    </Box>
  );
});

// Add this near your other input components (e.g., Followerstb, Employeetb)
const URLtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  return (
    <TextField
      placeholder="Enter URL"
      value={inputValue || ''}
      size='small'
      onChange={(event) => {
        if (!props.disabled) {
          setInputValue(event.target.value);
        }
      }}
      onBlurCapture={() => {
        if (!props.disabled) {
          props.addSocialField(props.i1, props.i2, props.i3, props.c, inputValue);
        }
      }}
      disabled={props.disabled}
      variant={conttbvariant}
      margin="normal"
      autoCorrect='false'
      InputProps={{ sx: { fontSize: 12, height: 25 } }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0,
      }}
    />
  );
});

// Add new UsernameTb component
const UsernameTb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      props.addSocialField(props.i1, props.i2, props.i3, 'userFullName', inputValue);
    }
  };

  return (
    <TextField
      placeholder="user-full-name"
      value={inputValue || ''}
      size='small'
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.addSocialField(props.i1, props.i2, props.i3, 'userFullName', inputValue);
      }}
      onKeyPress={handleInputKeyPress}
      variant={conttbvariant}
      margin="normal"
      autoCorrect='false'
      InputProps={{ sx: { fontSize: 12, height: 25 } }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0,
      }}
    />
  );
});

const NoSocialsCheckbox = memo((props) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setIsChecked(newValue);
    console.log(`NoSocialsCheckbox changed: i1=${props.i1}, in=${props.in}, newValue=${newValue}`);
    props.setNoSocials(props.i1, props.in, newValue);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          size="small"
          sx={{ padding: '2px', '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={handleChange}
        />
      }
      label={<Typography variant="caption" sx={{ fontSize: 12 }}>NS</Typography>}
      sx={{ margin: 0, height: 25, '& .MuiFormControlLabel-label': { marginLeft: '2px' } }}
    />
  );
});

const InfluencerCheckbox = memo((props) => {
  const [isChecked, setIsChecked] = useState(props.checked);

  useEffect(() => {
    setIsChecked(props.checked);
  }, [props.checked]);

  const handleChange = (event) => {
    const newValue = event.target.checked;
    setIsChecked(newValue);
    console.log(`InfluencerCheckbox changed: i1=${props.i1}, in=${props.in}, newValue=${newValue}`);
    props.setIsInfluencer(props.i1, props.in, newValue);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          size="small"
          sx={{ padding: '2px', '& .MuiSvgIcon-root': { fontSize: 16 } }}
          onChange={handleChange}
        />
      }
      label={<Typography variant="caption" sx={{ fontSize: 12 }}>INF</Typography>}
      sx={{ margin: 0, height: 25, '& .MuiFormControlLabel-label': { marginLeft: '2px' } }}
    />
  );
});

const LeadStatusDropdown = memo((props) => {
  const [selectedValue, setSelectedValue] = useState(props.value || 'active');

  useEffect(() => {
    setSelectedValue(props.value || 'active');
  }, [props.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    console.log(`LeadStatusDropdown changed: i1=${props.i1}, in=${props.in}, newValue=${newValue}`);
    props.setLeadStatus(props.i1, props.in, newValue);
  };

  return (
    <Select
      value={selectedValue}
      size="small"
      onChange={handleChange}
      sx={{
        height: 25,
        fontSize: 12,
        minWidth: 80,
        '& .MuiSelect-select': {
          padding: '2px 24px 2px 8px',
          height: '21px',
          lineHeight: '21px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }
      }}
    >
      <MenuItem value="active" sx={{ fontSize: 12, height: 25, minHeight: 'unset' }}>Active</MenuItem>
      <MenuItem value="inactive" sx={{ fontSize: 12, height: 25, minHeight: 'unset' }}>Inactive</MenuItem>
      <MenuItem value="zero" sx={{ fontSize: 12, height: 25, minHeight: 'unset' }}>Zero</MenuItem>
    </Select>
  );
});