import React, { useState } from 'react';
import { useUser } from '../UserProvider';
import { allocateDomainToTeams, allocateDomainToProcessors } from '../api';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Tabs, Tab,
     FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Chip} from '@mui/material';
import { Person, Groups } from '@mui/icons-material';

function AllocationDialog({
  open,
  onClose,
  selectedIds,
  onSuccess,
  onError,
  onComplete
}) {
  const { user } = useUser();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedUsers([]);
  };

  const handleUserSelect = (event) => {
    const value = event.target.value;
    if (Array.isArray(value)) {
      setSelectedUsers(value);
    } else {
      setSelectedUsers(prev => {
        const exists = prev.find(user => user.email === value.email);
        if (exists) {
          return prev.filter(user => user.email !== value.email);
        } else {
          return [...prev, value];
        }
      });
    }
  };

  const handleSubmit = async () => {
    if (selectedUsers.length === 0 || selectedIds.length === 0) return;

    setLoading(true);
    try {
      const selectedUserEmails = selectedUsers.map((obj) => obj.email);
      const allocationPromise = activeTab === 0
        ? allocateDomainToTeams(selectedIds, selectedUserEmails)
        : allocateDomainToProcessors(selectedIds, selectedUserEmails);

      await allocationPromise;
      
      if (onSuccess) onSuccess();
      handleClose();
    } catch (error) {
      console.error("Allocation failed", error);
      if (onError) onError();
    } finally {
      setLoading(false);
      if (onComplete) onComplete();
    }
  };

  const handleClose = () => {
    setSelectedUsers([]);
    setActiveTab(0);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
          overflow: 'hidden',
          maxWidth: '600px',
          margin: '16px'
        }
      }}
    >
      <DialogTitle
        sx={{
          pt: 2,
          px: 3,
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#fff'
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '0.95rem',
              fontWeight: 500,
              color: '#8797A8',
              '&.Mui-selected': {
                color: '#1976d2',
                fontWeight: 600
              }
            }
          }}
        >
          <Tab label="Allocate to Team" />
          <Tab label="Allocate to Processor" />
        </Tabs>
      </DialogTitle>

      <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
        <DialogContentText sx={{
          fontSize: '0.875rem',
          color: '#666',
          mb: 2,
          mt: 1,
          fontWeight: 500
        }}>
          {selectedIds.length} domain{selectedIds.length !== 1 && 's'} selected
        </DialogContentText>

        <Box sx={{
          p: 3,
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
        }}>
          <FormControl fullWidth>
            <InputLabel id="user-select-label" sx={{ color: '#666' }}>
              {activeTab === 0 ? 'Select Team' : 'Select Processors'}
            </InputLabel>
            <Select
              labelId="user-select-label"
              multiple
              value={selectedUsers}
              onChange={handleUserSelect}
              input={<OutlinedInput label={activeTab === 0 ? 'Select Team' : 'Select Processors'} />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value.email}
                      label={activeTab === 0
                        ? `Team ${value.firstName} ${value.lastName}`
                        : value.email === user.email
                          ? 'Me'
                          : `${value.firstName} ${value.lastName}`
                      }
                      sx={{
                        backgroundColor: '#E3F2FD',
                        color: '#1976d2',
                        fontWeight: 500,
                        fontSize: '0.8rem'
                      }}
                    />
                  ))}
                </Box>
              )}
            >
              {activeTab === 0 ? (
                user.teams.map((child) => (
                  <MenuItem key={child.email} value={child}>
                    <Checkbox
                      checked={selectedUsers.some(selected => selected.email === child.email)}
                      sx={{
                        color: '#8797A8',
                        '&.Mui-checked': {
                          color: '#1976d2'
                        }
                      }}
                    />
                    <ListItemText
                      primary={`Team ${child.firstName} ${child.lastName}`}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: '#2f3542'
                        }
                      }}
                    />
                  </MenuItem>
                ))
              ) : (
                [
                  <MenuItem key={user.email} value={user}>
                    <Checkbox
                      checked={selectedUsers.some(selected => selected.email === user.email)}
                      sx={{
                        color: '#8797A8',
                        '&.Mui-checked': {
                          color: '#1976d2'
                        }
                      }}
                    />
                    <ListItemText
                      primary="Me"
                      primaryTypographyProps={{
                        sx: {
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: '#2f3542'
                        }
                      }}
                    />
                  </MenuItem>,
                  ...user.users.map((child) => (
                    <MenuItem key={child.email} value={child}>
                      <Checkbox
                        checked={selectedUsers.some(selected => selected.email === child.email)}
                        sx={{
                          color: '#8797A8',
                          '&.Mui-checked': {
                            color: '#1976d2'
                          }
                        }}
                      />
                      <ListItemText
                        primary={`${child.firstName} ${child.lastName}`}
                        primaryTypographyProps={{
                          sx: {
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            color: '#2f3542'
                          }
                        }}
                      />
                    </MenuItem>
                  ))
                ]
              )}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{
        py: 2,
        px: 3,
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#fff'
      }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading || selectedUsers.length === 0}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            px: 3,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          Allocate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AllocationDialog; 