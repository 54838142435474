import { Alert, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Snackbar, Stack, Switch, Tab, ThemeProvider, Typography, TextField, InputAdornment, Autocomplete } from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Add, FiberManualRecord, PanToolAltOutlined } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useUser } from "../UserProvider";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Sort as SortIcon } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import AllocationDialog from '../Common/AllocationDialog';
import { getNonAlloc } from "../api";

export default function NonAllocated() {
  // State management
  const [tvalue, setTvalue] = useState('Ltd');
  const [idds, setIdds] = useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [domains, setDomains] = useState([]);
  const [portfolio, setPortfolio] = useState('ALL');
  const portfolioTypes = ['ALL', 'LTD', 'OWNED', 'PD', 'ALD'];

  // Table state
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  const { user } = useUser();

  // Handlers
  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
  };

  const handleTabChange = (event, newValue) => {
    setPortfolio(newValue);
  };

  const getColumnsForPortfolio = useCallback((portfolioType) => {
    const baseColumns = [
      {
        id: 'domain',
        accessorKey: 'domain.domain',
        header: 'Domain',
        size: 200
      },
      {
        id: 'dateOfAlloc',
        accessorKey: 'dateOfAlloc',
        header: 'Date',
        size: 100,
        accessorFn: (row) => new Date(row.dateOfAlloc),
        Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
      },
      {
        id: 'deadline',
        accessorKey: 'deadline',
        header: 'Deadline',
        accessorFn: (row) => row.domain.deadline ? new Date(row.domain.deadline) : 'N/A',
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value === 'N/A' ? 'N/A' : value.toISOString().split('T')[0];
        },
      },
      {
        id: 'orderDate',
        accessorKey: 'orderDate',
        header: 'Order Date',
        accessorFn: (row) => row.domain.orderDate ? new Date(row.domain.orderDate) : 'N/A',
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value === 'N/A' ? 'N/A' : value.toISOString().split('T')[0];
        },
      },
      {
        id: 'orderStatus',
        accessorKey: 'domain.orderStatus',
        header: 'Order Status',
        size: 100,
        accessorFn: (row) => row.domain.orderStatus ?? 'N/A',
      },
      {
        id: 'est',
        accessorKey: 'EST',
        header: 'EST',
        size: 80,
        accessorFn: (row) => row.domain.EST ?? 'N/A',
      },
      {
        id: 'pp',
        accessorKey: 'PP',
        header: 'PP',
        size: 80,
        accessorFn: (row) => row.domain.PP ?? 'N/A',
      },
      {
        id: 'allocator',
        accessorKey: 'allocator',
        header: 'Allocator',
        size: 150,
        accessorFn: (row) => row.allocator ? `${row.allocator.firstName} ${row.allocator.lastName}` : 'N/A',
      }
    ];

    if (portfolioType !== 'ALL') {
      const columnsToShow = {
        'LTD': ['dateOfAlloc', 'deadline', 'domain', 'est', 'allocator'],
        'OWNED': ['dateOfAlloc', 'domain', 'pp', 'est','allocator'],
        'PD': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'allocator'],
        'ALD': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'allocator'],
      };

      return baseColumns.filter(column => columnsToShow[portfolioType].includes(column.id));
    }

    return baseColumns;
  }, [domains, setDomains]);

  const columns = useMemo(() => getColumnsForPortfolio(portfolio), [portfolio, getColumnsForPortfolio]);


  // Styles
  const commonButtonStyle = {
    textTransform: 'none',
    backgroundColor: '#8797A8',
    color: '#FFFFFF !important',
    height: '34px',
    padding: '4px 8px',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: '#8797A8',
      opacity: 0.9,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#8797A8',
      opacity: 0.7,
      color: '#FFFFFF !important',
    }
  };

  const searchFieldStyles = {
    width: '160px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '0.875rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderRadius: '12px',
      },
      '&:hover fieldset': {
        borderColor: '#8797A8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8797A8',
      },
    },
  };

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    getNonAlloc({
      portfolio: portfolio !== 'ALL' ? portfolio.toLowerCase() : "all",
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          setDomains(response.data.content);
          setTotalRowCount(response.data.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomains([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
    }, [portfolio, pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]); // Include only the state dependencies needed

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Table configuration
  const domainTable = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
      }
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      }
    },

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Button
          variant="outlined"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={commonButtonStyle}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={commonButtonStyle}
        >
          Sort
        </Button>
        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<PanToolAltOutlined />}
          onClick={() => {
            setOpenAllocate(true);
            let domainIds = table.getSelectedRowModel().rows.map(
              (row) => row.original.domain.id
            );
            setIdds(domainIds);
          }}
          sx={commonButtonStyle}
        >
          Allocate
        </Button>

        <Box flexGrow={1} />

        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={searchFieldStyles}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon 
                  sx={{ 
                    fontSize: '1rem', 
                    color: '#8797A8',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#6b7a8a',
                    },
                  }}
                  onClick={handleSearch} 
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Non Allocated List
          </Typography>
        </Stack>

        {/* Snackbar notifications */}
        <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setUpsuc(false)}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>
        
        <Snackbar 
          open={upfail} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpfail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Not Uploaded!
          </Alert>
        </Snackbar>

        <Snackbar 
          open={upsuc1} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpsuc1(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Allotted!
          </Alert>
        </Snackbar>

        <Snackbar 
          open={upfail1} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpfail1(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Allotted!
          </Alert>
        </Snackbar>

        {/* Tab Context */}
        <TabContext value={portfolio}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {portfolioTypes.map((type) => (
                <Tab
                  key={type}
                  label={type}
                  value={type}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>

          {/* Allocation Dialog */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MultiFilterDialog
              open={openFilterDialog}
              onClose={() => setOpenFilterDialog(false)}
              columns={columns}
              initialFilters={columnFilters}
              onApplyFilter={handleFilterApply}
            />
            <MultiSortDialog
              open={openSortDialog}
              onClose={() => setOpenSortDialog(false)}
              columns={columns}
              initialSorting={sorting}
              onApplySort={handleSortApply}
            />
            <AllocationDialog 
              open={openAllocate}
              onClose={handleCloseAllocate}
              selectedIds={idds}
              onSuccess={() => {
                setUpsuc1(true);
                fetchData();
              }}
              onError={() => setUpfail1(true)}
              onComplete={handleCloseAllocate}
            />
          </LocalizationProvider>

          {/* Tab Panels */}
          {portfolioTypes.map((type) => (
            <TabPanel key={type} value={type} sx={{ padding: 0 }}>
              <Box>
                <MaterialReactTable table={domainTable} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
      </Stack>
    </ThemeProvider>
  );
}