import { Alert, Box, Button, Dialog, DialogContentText, Tabs,FormControlLabel, FormGroup, IconButton, Snackbar, Stack, Switch, Tab, ThemeProvider, Tooltip, Typography, TextField, InputAdornment, ToggleButtonGroup, ToggleButton, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Chip } from "@mui/material";
import { theme } from "../Theme";
import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import {getAllocatedByMeP, getAllocatedByMeT, reallocateToProcessorP, reallocateToProcessorT, reallocateToTeamP, reallocateToTeamT } from "../api";
import {Sort as SortIcon, Create, DoneOutline, Stop, FiberManualRecord, PanToolAltOutlined, Person, Groups } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import { useUser } from '../UserProvider';

// Status Icon Component
const StatusIcon = memo(({ status }) => {
  if (status === 0) {
    return (
      <Tooltip title="Not Started Processing">
        <Stop sx={{ 
          color: '#8797A8',
          fontSize: '1.2rem' 
        }} />
      </Tooltip>
    );
  } else if (status === 1) {
    return (
      <Tooltip title="Processing">
        <Create sx={{ 
          color: '#FFC0CB',
          fontSize: '1.2rem'
        }} />
      </Tooltip>
    );
  } else if (status === 2) {
    return (
      <Tooltip title="Processed">
        <DoneOutline sx={{ 
          color: '#4CAF50',
          fontSize: '1.2rem'
        }} />
      </Tooltip>
    );
  }
  return null;
});

export default function AllocatedByMe() {
  // State management
  const [domains, setDomains] = useState([]);
  const [portfolio, setPortfolio] = useState('ALL');
  const [agentType, setAgentType] = useState('Processors');
  const [openAllocate, setOpenAllocate] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination, sorting, filtering states
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [idds, setIdds] = useState([]);
  
  // Dialog states
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  
  // Snackbar states
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);

  // Add these new states
  const [checked, setChecked] = useState(true);
  const [processor, setProcessor] = useState('');
  const [team, setTeam] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const { user } = useUser();
  const [activeTab, setActiveTab] = useState(0);

  // Common styles
  const commonButtonStyle = {
    textTransform: 'none',
    backgroundColor: '#8797A8',
    color: '#FFFFFF !important',
    height: '34px',
    padding: '4px 8px',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: '#8797A8',
      opacity: 0.9,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#8797A8',
      opacity: 0.7,
      color: '#FFFFFF !important',
    }
  };

  const searchFieldStyles = {
    width: '160px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '0.875rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderRadius: '12px',
      },
      '&:hover fieldset': {
        borderColor: '#8797A8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8797A8',
      },
    },
  };

  // Handlers
  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  const handleTabChange = (event, newValue) => {
    setPortfolio(newValue);
  };

  // Add these handlers
  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };

  // Data fetching
  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    const apiCall = agentType === 'Processors' ? getAllocatedByMeP : getAllocatedByMeT;

    apiCall({
      portfolio: portfolio !== 'ALL' ? portfolio.toLowerCase() : "all",
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          setDomains(response.data.content);
          setTotalRowCount(response.data.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomains([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [portfolio, agentType, columnFilters, sorting, pagination.pageIndex, pagination.pageSize, globalSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Column definitions
  const getColumnsForPortfolio = useCallback((portfolioType, agentType) => {
    const baseColumns = [
      {
        id: 'dateOfAlloc',
        accessorKey:'dateOfAlloc',
        header: 'Date',
        accessorFn: (row) => row.dateOfAlloc ? new Date(row.dateOfAlloc) : null,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value ? value.toISOString().split('T')[0] : "-";
        },
        size: 100,
      },
      {
        id: 'domain',
        accessorKey:'domain',
        header: 'Domain',
        accessorFn: (row) => row.domain?.domain ?? "-",
        size: 210,
      },
      {
        id: 'status',
        accessorKey:'status',
        header: 'Status',
        accessorFn: (row) => row.status,
        Cell: ({ cell }) => <StatusIcon status={cell.getValue() ?? 0} />,
        size: 80,
      },
      {
        id: 'deadline',
        accessorKey:'deadline',
        header: 'Deadline',
        accessorFn: (row) => row.domain?.deadline ? new Date(row.domain.deadline) : null,

        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value ? value.toISOString().split('T')[0] : "-";
        },
        size: 100,
      },
      {
        id: 'orderDate',
        accessorKey:'orderDate',
        header: 'Order Date',
        accessorFn: (row) => row.domain?.orderDate ? new Date(row.domain.orderDate) : null,

        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value ? value.toISOString().split('T')[0] : "-";
        },
        size: 100,
      },
      {
        id: 'orderStatus',
        accessorKey:'orderStatus',
        header: 'Order Status',
        accessorFn: (row) => row.domain?.orderStatus ?? "-",
        size: 70,
      },
      {
        id: 'est',
        accessorKey:'est',
        header: 'EST',
        accessorFn: (row) => row.domain?.EST ?? "-",
        size: 70,
      },
      {
        id: 'pp',
        accessorKey:'pp',
        header: 'PP',
        accessorFn: (row) => row.domain?.PP ?? "-",
        size: 70,
      },
      {
        id: 'user',
        accessorKey:'user',
        header: agentType === 'Processors' ? 'Processor' : 'Team',
        accessorFn: (row) => {
          if (!row.user) return "-";
          return agentType === 'Processors' 
            ? `${row.user.firstName} ${row.user.lastName}`
            : `Team ${row.user.firstName}`;
        },
        size: 110,
      }
    ];

    // Portfolio-specific columns
    const columnsToShow = {
      'LTD': {
        'Processors': ['dateOfAlloc', 'deadline', 'domain', 'est', 'user','status'],
        'Teams': ['dateOfAlloc', 'deadline', 'domain', 'est', 'user','status']
      },
      'OWNED': {
        'Processors': ['dateOfAlloc', 'domain', 'pp', 'est', 'user','status'],
        'Teams': ['dateOfAlloc', 'domain', 'pp', 'est', 'user','status']
      },
      'PD': {
        'Processors': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'user','status'],
        'Teams': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'user','status']
      },
      'ALD': {
        'Processors': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'user','status'],
        'Teams': ['dateOfAlloc', 'orderDate', 'orderStatus', 'domain', 'est', 'user','status']
      }
    };

    if (portfolioType !== 'ALL') {
      return baseColumns.filter(column => 
        columnsToShow[portfolioType][agentType].includes(column.id)
      );
    }

    return baseColumns;
  }, []);

  const columns = useMemo(() => 
    getColumnsForPortfolio(portfolio, agentType), 
    [portfolio, agentType, getColumnsForPortfolio]
  );

  // Table configuration
  const domainTable = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
      }
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      }
    },

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Button
          variant="outlined"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={commonButtonStyle}
        >
          Filter
        </Button>

        <Button
          variant="outlined"
          size="small"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={commonButtonStyle}
        >
          Sort
        </Button>
        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<PanToolAltOutlined />}
          onClick={() => {
            setOpenAllocate(true);
            let id = table.getSelectedRowModel().rows.map((row) => row.original.id);
            setIdds(id);
          }}
          sx={commonButtonStyle}
        >
          Allocate
        </Button>

        <Box flexGrow={1} />

        <ToggleButtonGroup
          size='small'
          color="primary"
          value={agentType}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setAgentType(newValue);
            }
          }}
          aria-label="Agent Type"
          sx={{
            height: '32px',
            '& .MuiToggleButtonGroup-grouped': {
              border: '1px solid #e0e0e0',
              '&:not(:first-of-type)': {
                borderLeft: '1px solid #e0e0e0',
              },
            },
          }}
        >
          <ToggleButton 
            value="Processors" 
            sx={{
              textTransform: 'none',
              px: 2,
            }}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              <FiberManualRecord fontSize='small' sx={{fontSize: 12, color:'#FFC682'}}/>
              <Typography sx={{
                fontSize: '0.75rem',
                fontWeight: '600',
                color: '#49454f',
              }}>
                Processors
              </Typography>
            </Stack>
          </ToggleButton>
          <ToggleButton 
            value="Teams" 
            sx={{
              textTransform: 'none',
              px: 2,
            }}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              <FiberManualRecord fontSize='small' sx={{fontSize: 12, color:'#C6CFFF'}}/>
              <Typography sx={{
                fontSize: '0.75rem',
                fontWeight: '600',
                color: '#49454f',
              }}>
                Teams
              </Typography>
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>

        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={searchFieldStyles}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon 
                  sx={{ 
                    fontSize: '1rem', 
                    color: '#8797A8',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#6b7a8a',
                    },
                  }}
                  onClick={handleSearch} 
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });

  const portfolioTypes = ['ALL', 'LTD', 'OWNED', 'PD', 'ALD'];

  // Update handleCloseAllocate
  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setSelectedUser(null);
    setActiveTab(0);
  };

  // Update the Allocate button onClick
  const handleAllocation = () => {
    setLoading(true);
    const selectedUserEmail = selectedUser.email;
    
    const apiCall = activeTab === 0
      ? (agentType === 'Processors' ? reallocateToTeamP : reallocateToTeamT)
      : (agentType === 'Processors' ? reallocateToProcessorP : reallocateToProcessorT);

    apiCall(idds, selectedUserEmail)
      .then(() => {
        setUpsuc1(true);
        fetchData();
        handleCloseAllocate(); 
        setIdds([]);
        domainTable.resetRowSelection();
      })
      .catch(() => {
        setUpfail1(true);
        // Don't close dialog on error to allow retry
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Allocated By Me
          </Typography>
        </Stack>

        <TabContext value={portfolio}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {portfolioTypes.map((type) => (
                <Tab
                  key={type}
                  label={type}
                  value={type}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {portfolioTypes.map((type) => (
            <TabPanel key={type} value={type} sx={{ padding: 0 }}>
              <Box>
                <MaterialReactTable table={domainTable} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>

        {/* Snackbars */}
        <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Moved to W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Moved to W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Allotted!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Allotted!
          </Alert>
        </Snackbar>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <Dialog
            fullWidth
            open={openAllocate}
            onClose={handleCloseAllocate}
            onBackdropClick={handleCloseAllocate}
            PaperProps={{
              sx: {
                borderRadius: '8px',
                boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                overflow: 'hidden',
                maxWidth: '600px',
                margin: '16px'
              }
            }}
          >
            <DialogTitle
              sx={{
                pt: 2,
                px: 3,
                borderBottom: '1px solid #e0e0e0',
                backgroundColor: '#fff'
              }}
            >
              <Tabs
                value={activeTab}
                onChange={(event, newValue) => {
                  setActiveTab(newValue);
                  setSelectedUser(null);
                }}
                sx={{
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    color: '#8797A8',
                    '&.Mui-selected': {
                      color: '#1976d2',
                      fontWeight: 600
                    }
                  }
                }}
              >
                <Tab label="Allocate to Team" />
                <Tab label="Allocate to Processor" />
              </Tabs>
            </DialogTitle>

            <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
              <DialogContentText sx={{
                fontSize: '0.875rem',
                color: '#666',
                mb: 2,
                mt: 1,
                fontWeight: 500
              }}>
                {idds.length} domain{idds.length !== 1 && 's'} selected
              </DialogContentText>

              <Box sx={{
                p: 3,
                backgroundColor: '#fff',
                borderRadius: '6px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
              }}>
                <FormControl fullWidth>
                  <InputLabel id="user-select-label" sx={{ color: '#666' }}>
                    {activeTab === 0 ? 'Select Team' : 'Select Processor'}
                  </InputLabel>
                  <Select
                    labelId="user-select-label"
                    value={selectedUser || ''}
                    onChange={handleUserSelect}
                    input={<OutlinedInput label={activeTab === 0 ? 'Select Team' : 'Select Processor'} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip
                          label={activeTab === 0
                            ? `Team ${selected.firstName} ${selected.lastName}`
                            : selected.email === user.email
                              ? 'Me'
                              : `${selected.firstName} ${selected.lastName}`
                          }
                          sx={{
                            backgroundColor: '#E3F2FD',
                            color: '#1976d2',
                            fontWeight: 500,
                            fontSize: '0.8rem'
                          }}
                        />
                      </Box>
                    )}
                  >
                    {activeTab === 0 ? (
                      user.teams.map((child) => (
                        <MenuItem key={child.email} value={child}>
                          <ListItemText
                            primary={`Team ${child.firstName} ${child.lastName}`}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                color: '#2f3542'
                              }
                            }}
                          />
                        </MenuItem>
                      ))
                    ) : (
                      [
                        <MenuItem key={user.email} value={user}>
                          <ListItemText
                            primary="Me"
                            primaryTypographyProps={{
                              sx: {
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                color: '#2f3542'
                              }
                            }}
                          />
                        </MenuItem>,
                        ...user.users.map((child) => (
                          <MenuItem key={child.email} value={child}>
                            <ListItemText
                              primary={`${child.firstName} ${child.lastName}`}
                              primaryTypographyProps={{
                                sx: {
                                  fontSize: '0.875rem',
                                  fontWeight: 500,
                                  color: '#2f3542'
                                }
                              }}
                            />
                          </MenuItem>
                        ))
                      ]
                    )}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>

            <DialogActions sx={{
              py: 2,
              px: 3,
              borderTop: '1px solid #e0e0e0',
              backgroundColor: '#fff'
            }}>
              <Button
                onClick={handleCloseAllocate}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  borderColor: '#1976d2',
                  color: '#1976d2',
                  '&:hover': {
                    borderColor: '#1976d2',
                    backgroundColor: 'rgba(25, 118, 210, 0.04)'
                  }
                }}
              >
                Cancel
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                onClick={handleAllocation}
                variant="contained"
                disabled={loading || !selectedUser}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  px: 3,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
              >
                Allocate
              </Button>
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </Stack>
    </ThemeProvider>
  );
}