import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Button, Typography } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { getUserLastLocation } from "../Components/api";

// Custom icon (optional)
const customIcon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export default function UserLocationList() {
    const [data, setData] = useState([]);
    const [view, setView] = useState("table");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const response = await getUserLastLocation();
        setData(response.data);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "userId",
                header: "User ID",
                size: 200
            },
            {
                accessorKey: "latitude",
                header: "Latitude",
            },
            {
                accessorKey: "longitude",
                header: "Longitude",
            },
            {
                accessorKey: "address",
                header: "Address",
                size: 350,
            },
            {
                accessorKey: "timestamp",
                header: "Timestamp",
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
            },
        ],
        []
    );

    const lastLocation = data.length > 0 ? data[data.length - 1].address : "No data available";

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Last Location
                </Typography>
                <Button variant="contained" onClick={() => setView(view === "map" ? "table" : "map")}>
                    {view === "map" ? "Table View" : "Map View"}
                </Button>
            </Box>

            {view === "table" ? (
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableSorting
                    enableColumnResizing
                    enableRowSelection
                    initialState={{
                        columnVisibility: {
                            id: false, // Hide the ID column
                        },
                    }}
                />
            ) : (
                <MapContainer
                    center={[28.5540974, 77.338442]}
                    zoom={13}
                    style={{ height: "600px", width: "100%" }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />

                    {data.map((location) => (
                        <Marker
                            key={location.id}
                            position={[location.latitude, location.longitude]}
                            icon={customIcon}
                        >
                            <Popup>
                                <strong>User ID:</strong> {location.userId}
                                <br />
                                <strong>Address:</strong> {location.address}
                                <br />
                                <strong>Timestamp:</strong>{" "}
                                {new Date(location.timestamp).toLocaleString()}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            )}
        </Box>
    );
}
