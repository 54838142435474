import axios from "axios";
import axiosInstance from "./axiosInstance";

export const getNonAlloc = (props) => {
   return axiosInstance.get("/v1/getnonallocated", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
         portfolio: props.portfolio
      }
   });
}

export const getPresentAllocs = (props) => {
   return axiosInstance.get("/v1/getpresentallocations", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
         portfolio: props.portfolio
      }
   });
}

export const getAllocatedByMeP=(props)=>
   {
      return axiosInstance.get("/v1/getallocatedbymep", { 
         params: {
            page: props.page,
            size: props.size,
            sort: props.sort,
            filter: props.filter,
            search: props.search,
            portfolio: props.portfolio
         }
      });
   }
   
export const getAllocatedByMeT=(props)=>
   {
      return axiosInstance.get("/v1/getallocatedbymet", { 
         params: {
            page: props.page,
            size: props.size,
            sort: props.sort,
            filter: props.filter,
            search: props.search,
            portfolio: props.portfolio
         }
      });
   }

export const getAllTeams=()=>
{
      return axiosInstance.get("/getallteams");
}

export const getAllTeamsAndUsers=()=>
   {
         return axiosInstance.get("/getallteamsandusers");
   }

export const getPresentAllocsWI=()=>
{
   return axiosInstance.get("/getpresentallocationswi");
}

export const getPresentAllocsPQ=(date1,date2)=>
{
   return axiosInstance.get("/getpresentallocationspq");
}

export const getPQs=(date1,date2)=>
{
   return axiosInstance.get("/getpqs",{params:{date1,date2}});
}
export const setPricesPQ=(id,listPrice,floorPrice)=>
   {
      return axiosInstance.get("/setpricespq",{params:{id,listPrice,floorPrice}});
   }
export const getDomainPQs=(id)=>
{
   return axiosInstance.get("/getdomainpqs",{params:{id}});
}

export const exportContactsToCRM=(data,tag,userId)=>
   {
      return axios.post("https://crmapi.namekart.com/api/uploadContactsFromDashboard",{csvValues:data,tag:tag,titleId:tag,userId:userId});
   }

export const getDayWork=(date,user)=>
{
   return axiosInstance.get("/getdaywork",{params:{date,user}});
}

export const getTodayWork=(date)=>
{
   return axiosInstance.get("/gettodaywork",{params:{date}});
}

export const saveTodayWork=(date,presentAllocs)=>
{
   console.log("saving today work")
   return axiosInstance.post("/savetodaywork",{list:presentAllocs,date:date});
}

export const getDayWorkId=(id)=>
{
   return axiosInstance.get("/getdayworkid",{params:{id}});
}

export const setBG=(b,id)=>
{
   return axiosInstance.get("/setbg",{params:{b,id}});
}

export const saveLeadQA=(id,impMissing,irrelevant,pemailm)=>
{
   return axiosInstance.get("/saveleadqa",{params:{id,impMissing,irrelevant,pemailm}});
}

export const getGoogleSheetData=(url)=>
{
   return axios.get(url);
}


export const getMissedLeads=(id)=>
{
   return axiosInstance.get("/getmissedleads",{params:{id}});
}

export const getMySocialAllocations=()=>
{
   return axiosInstance.get("/getmysocialallocations");
}
export const getSocialsBank=()=>
{
   return axiosInstance.get("/getsocialsbank");
}


export const editDomain=(values,id)=>
{
   return axiosInstance.post(`/editdomain/${id}`,values);
}

export const getRegistrars=()=>
{
   return axiosInstance.get("/getregistrars");
}

export const getAllOwnedDomain=()=>
   {
      return axiosInstance.get("/getallowneddomains");
   }




export const getAllLtdDomains=()=>
{
   return axiosInstance.get("/getallltddomains");
}

export const getAllPDDomains = () => {
   return axiosInstance.get("/getallpddomains");
}


export const getAllAldDomains = () => {
   return axiosInstance.get("/getallalddomains");
}



export const getAllTags=()=>
{
   return axiosInstance.get("/getalltags");
}


export const startAnalysis=(id)=>
{
   return axiosInstance.get("/startanalysis",{params:{id}});
}

export const updateMIS=(id)=>
{
   return axiosInstance.get("/updatemis",{params:{id}});
}

export const submitMIS=(id,tor,pf,remark,endAnswer)=>
{
   return axiosInstance.get("/submitmis",{params:{id,tor,pf,remark,endAnswer}});
}

export const getTeamQAReports=(date,date1)=>
{
   return axiosInstance.get("/getteamqareports",{params:{date,date1}});
}



export const getTeamWorkArchivesSubmitted=(date,date1)=>
   {
      return axiosInstance.get("/getteamworkarchivessubmitted",{params:{date,date1}});
   }

export const getTeamMISReportsSubmitted=(date,date1)=>
{
   return axiosInstance.get("/getteammisreportssubmitted",{params:{date,date1}});
}

export const getTeamMISReportsPending=(date,date1)=>
{
   return axiosInstance.get("/getteammisreportspending",{params:{date,date1}});
}


export const getAnalysedQAReports=(date,date1)=>
{
   return axiosInstance.get("/getanalysedqareports",{params:{date,date1}});
}


export const getChildren=()=>
{
   return axiosInstance.get("/getchildren");
}

export const getRestProcessors=()=>
   {
      return axiosInstance.get("/getrestprocessors");
   }

export const getProcessors=()=>
{
   return axiosInstance.get("/getprocessors");
}



export const getSocialChildren=()=>
{
   return axiosInstance.get("/getsocialchildren");
}
export const getTopLeadEmailAgent=()=>
{
   return axiosInstance.get("/gettopleademailagent");
}



export const getAllSocialEmployees=()=>
   {
      return axiosInstance.get("/getallsocialemployees");
   }
export const restartAnalysis=(id)=>
{
   return axiosInstance.get("/restartanalysis",{params:{id}});
}

export const stopAnalysis=(id)=>
{
   return axiosInstance.get("/stopanalysis",{params:{id}});
}

export const saveMissedLeads=(id,ml)=>
{
   return axiosInstance.post("/savemissedleads",{id,ml});
}

export const getUsersQA=()=>
{
   return axiosInstance.get("/getusersqa");
}

export const uploadEmployees=(employees)=>
{
   return axiosInstance.post("/uploademployees",employees);
}

export const uploadSocialEmployees=(employees)=>
   {
      return axiosInstance.post("/uploadsocialemployees",employees);
   }

export const uploadRegoDomains=(domains)=>
{
   return axiosInstance.post("/uploadregodomains",domains);
}

export const uploadDomains=(domains)=>
   {
      return axiosInstance.post("/uploaddomains",domains);
   }

export const uploadSocialDomains=(domains)=>
{
   return axiosInstance.post("/uploadsocialdomains",domains);
}

export const uploadOwnedDomains=(domains,registrar)=>
{
   return axiosInstance.post(`/uploadowneddomains/${registrar}`,domains);
}

export const syncOwnedDomains=(domains)=>
{
   return axiosInstance.post(`/syncowneddomains`,domains);
}

export const uploadOwnedDomainss=(domains,registrar="",overwrite)=>
   {
      return axiosInstance.post(`/uploadowneddomains/${overwrite}`,{domains,registrar});
   }

export const uploadLtdDomains=(domains,overwrite)=>
{
   return axiosInstance.post(`/uploaddomains/${overwrite}`,domains);
}

export const uploadPDDomains=(domains,overwrite)=>
{
   return axiosInstance.post(`/uploadpddomains/${overwrite}`,domains);
}
   
export const editAldDomains=(domains)=>
{
   return axiosInstance.put("/editalddomains",domains);
}  

export const editOwnedDomains=(domains)=>
{
   return axiosInstance.put(`/editowneddomains`,domains);
}
   
export const editLtdDomains=(domains)=>
{
   return axiosInstance.put("/editltddomains",domains);
}
   
export const editPDDomains=(domains)=>
{
   return axiosInstance.put("/editpddomains",domains);
}
      


export const uploadWork=(list)=>
{
   return axiosInstance.post("/uploadwork",list);
}
export const uploadWorkCustom=(list,workDate,user)=>
   {
      return axiosInstance.post("/uploadworkcustom",{list,date:workDate,user:user});
   }

export const syncUser=()=>
{
   return axiosInstance.get("/syncuser");
}

export const getUserNTeams=()=>
{
   return axiosInstance.get("/getusersandteams");
}

export const getDomainLiveData=()=>
{
   return axiosInstance.get("/getdomainlivedata");
}

export const getSocialAllocationsWork=()=>
{
   return axiosInstance.get("/getsocialallocationswork");
}

export const setSocialAllocationsWork=(allocations)=>
{
   return axiosInstance.post("/setsocialallocationswork",allocations);
}

export const getSocialsCollectedData=(domain,deadlineFrom,deadlineTo,dateOfAllocFrom,dateOfAllocTo,status,list,socialAgent)=>
{
   return axiosInstance.get("/getsocialscollecteddata",{params:{domain,deadlineFrom,deadlineTo,dateOfAllocFrom,dateOfAllocTo,status,list,socialAgent}});
}

export const workingpa=(id,working)=>
{
   return axiosInstance.get("/workingpa",{params:{id,working}});
}

export const workstatuspa=(id,status)=>
{
   return axiosInstance.get("/workstatuspa",{params:{id,status}});
}

export const workstationpa=(id,status)=>
   {
      return axiosInstance.get("/workstationpa",{params:{id,status}});
   }
export const setWorkstationpamulti=(idds)=>
{
         return axiosInstance.post("/setworkstationpamulti",idds);
}   
export const removeWorkstationpamulti=(idds)=>
   {
            return axiosInstance.post("/removeworkstationpamulti",idds);
   }

export const allocateToProcessor=(ids,email)=>
{
   return axiosInstance.post(`/allocatetoprocessor/${email}`,ids);
}

export const allocateToTeam=(ids,email)=>
{
   return axiosInstance.post(`/allocatetoteam/${email}`,ids);
}

export const allocateToTeamUniversally=(domains,email)=>
   {
      return axiosInstance.post(`/allocateToTeamUniversally/${email}`,domains);
   }

export const allocateToProcessorUniversally=(domains,email)=>
   {
      return axiosInstance.post(`/allocateToProcessorUniversally/${email}`,domains);
      }

export const uploadAldDomains=(domains)=>
{
   return axiosInstance.post("/uploadalddomains",domains);
} 
               
export const allocateDomainToProcessor=(ids,email)=>
{
   return axiosInstance.post(`/allocatedomaintoprocessor/${email}`,ids);
}

export const allocateDomainToTeam=(ids,email)=>
{
   return axiosInstance.post(`/allocatedomaintoteam/${email}`,ids);
}

export const uploadAldDomainss=(domains,selectedDate,overwrite)=>
{
   return axiosInstance.post(`/uploadalddomains/${overwrite}`,{domains,selectedDate});
}  

export const allocateDomainToProcessors=(domainIDs,selectedUserEmails)=>
{
   return axiosInstance.post(`/allocatedomaintoprocessor`,{domainIDs,selectedUserEmails});
}

export const allocateDomainToTeams=(domainIDs,selectedUserEmails)=>
{
   return axiosInstance.post(`/allocatedomaintoteam`,{domainIDs,selectedUserEmails});
}

export const reallocateToProcessorP=(ids,email)=>
{
   return axiosInstance.post(`/reallocatetoprocessorp/${email}`,ids);
}

export const setStartAnswer=(startAnswer,date)=>
{
   return axiosInstance.get(`/setstartanswer`,{params:{startAnswer,date}});
}




export const allocateSocialToProcessor=(ids,email)=>
{
   return axiosInstance.post(`/allocatesocialtoprocessor/${email}`,ids);
}

export const allocateSocialDomain=(ids,email)=>
{
   return axiosInstance.post(`/allocatesocialdomain/${email}`,ids);
}

export const reallocateToTeamP=(ids,email)=>
{
   return axiosInstance.post(`/reallocatetoteamp/${email}`,ids);
}

export const reallocateToProcessorT=(ids,email)=>
{
   return axiosInstance.post(`/reallocatetoprocessort/${email}`,ids);
}

export const postPQ=(allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg,agentEmail)=>
{
   console.log({allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg,agentEmail})
   return axiosInstance.post(`/postpq`,{allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg,agentEmail});
}

export const putPQ=(pq)=>
   {
      //console.log({allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg})
      return axiosInstance.post(`/putpq`,pq);
   }

   export const deletePQ=(id)=>
      {
         //console.log({allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg})
         return axiosInstance.get(`/deletepq`,{params:{id}});
      }

   export const quotePrice=(id,quote)=>
      {
         //console.log({allocId,newLead,leadId,leadName,res,date,name,email,designation,topLead,leadSocials,topLeadSocials,sugg})
         return axiosInstance.get(`/quoteprice`,{params:{id,quote}});
      }

export const reallocateToTeamT=(ids,email)=>
{
   return axiosInstance.post(`/reallocatetoteamt/${email}`,ids);
}


export const saveUserLocation = (location) => {
   return axiosInstance.post(`/userlocation`, location);
}

export const getUserLocation = () => {
   return axiosInstance.get(`/userlocation`);
}

export const getUserLastLocation = () => {
   return axiosInstance.get(`/userlocation/last-locations`);
}

export const allocateShortlistedDomain = (selectedIds, user) => {
   return axiosInstance.post(`/userlocation`, selectedIds);
}

/////////////////Apis for AcquShortlistedDomains//////////////////////

export const uploadShortlistedDomains = (newDomains) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains`, newDomains);
}

export const uploadShortlistedDomainES = (newDomains) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/es`, newDomains);
}

export const getAcquShortlistedDomains = (researchState, showDomainWithLeadsOnly) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/by-research-state`, { 
     params: { 
       researchState, 
       sourceType: "ed",
       showDomainWithLeadsOnly 
     } 
   });
}

export const getEsShortlistedDomains = (researchState, showDomainWithLeadsOnly) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/by-research-state`, { 
     params: { 
       researchState, 
       sourceType: "es",
       showDomainWithLeadsOnly 
     } 
   });
}

export const getEsAnalystShortlistedDomains = (researchState, showDomainWithLeadsOnly) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/by-research-state/es-analyst`, { 
     params: { 
       researchState, 
       sourceType: "es",
       showDomainWithLeadsOnly 
     } 
   });
}

export const getArchiveDomains = (researchState) => {
   return axiosInstance.get(`/acqu-shortlisted-domains`, { params: { processingStates: researchState, sourceType: "ed" } });
}

export const getEsArchiveDomains = (researchState) => {
   return axiosInstance.get(`/acqu-shortlisted-domains`, { params: { processingStates: researchState, sourceType: "es" } });
}

export const getAcquArchiveDomains = (processingStates, page, size) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/shortlisted-domains-for-aq-archive`,{ params: { processingStates: processingStates , sourceType: "ed", page: page, size: size }});
}

export const getAcquEsArchiveDomains = (processingStates, page, size) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/shortlisted-domains-for-aq-archive`, { params: { processingStates: processingStates , sourceType: "es", page: page, size: size }});
}

export const getRecoHubData = (props) => {
   
   let endpoint;
   if (props.isNlDomain) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-reco';
   } else {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-reco';
   }
   
   return axiosInstance.get(endpoint, { 
      params: { 
         sourceType: "ed", 
         tab: props.tab
      }  
   });
}

export const getEsRecoHubData = (props) => {

   let endpoint;
   if (props.isNlDomain) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-reco';
   } else {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-reco';
   }
   
   return axiosInstance.get(endpoint, { 
      params: { 
         sourceType: "es", 
         tab: props.tab
      }  
   });
}

export const getDecisionHubData = (props) => {
   let endpoint;
   if (props.isNlDomain) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-apr';
   } else if (props.isZeroReco) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-zero-reco-for-apr';
   } else {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-apr';
   }
   
   return axiosInstance.get(endpoint, { 
      params: { 
         sourceType: "ed", 
         tab: props.tab
      }  
   });
}

export const getEsDecisionHubData = (props) => {
   let endpoint;
   if (props.isNlDomain) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-apr';
   } else if (props.isZeroReco) {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-zero-reco-for-apr';
   } else {
      endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-apr';
   }
   
   return axiosInstance.get(endpoint, { 
      params: { 
         sourceType: "es", 
         tab: props.tab
      }  
   });
}

export const getOrderHubData = (props) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/shortlisted-domains-for-order`, { params: { sourceType: "ed" , tab: props.tab} });
}

export const getEsOrderHubData = (props) => {
   return axiosInstance.get(`/v1/acqu-shortlisted-domains/shortlisted-domains-for-order`, { params: { sourceType: "es", tab: props.tab}  });
}

export const placeOrders = (selectedIds) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/placeorders`, selectedIds);
}

export const markOrderPlaced = (selectedIds) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/markorderplaced`, selectedIds);
}

export const sendBackToApr = (selectedIds) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/sendbacktoapr`, selectedIds);
}

export const fetchStats = (ids) => {
   return axiosInstance.put(`/acqu-shortlisted-domains/fetchstats`, ids);
}

export const updateRecoBulk = (updatedDomains) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/recommendation`, updatedDomains);
}

export const updateAprBulk = (updatedDomains, status) => {
   if (status === 'Approval') {
      return axiosInstance.put(`/v1/acqu-shortlisted-domains/approval`, updatedDomains);
   } else {
      return axiosInstance.put(`/v1/acqu-shortlisted-domains/unapproval`, updatedDomains);
   }
}


export const getPaginatedMasterData = (pageIndex, pageSize, filters) => {
   return axiosInstance.get("/masterdata", {
   params: {
      page: pageIndex,
      size: pageSize,
      ...filters
   }
});
}

export const getPaginatedAnalysisData = (pageIndex, pageSize, filters) => {
   return axiosInstance.get("/masterdata/analysisdomains", {
   params: {
      page: pageIndex,
      size: pageSize,
      ...filters
   }
});
}

export const shortlistDomain = (selectedDomain) => {
   return axiosInstance.post("/masterdata/analyseddomains/shortlist", selectedDomain);
}

export const shortlistAnalysisHub = (id, rating) => {
   return axiosInstance.get("/masterdata/analyseddomains/shortlist/v1", {params: {id, rating}});
}

export const unshortlistDomain = (selectedDomain) => {
   return axiosInstance.post("/masterdata/analyseddomains/unshortlist", selectedDomain);
}

export const moveToReco = (domainIds) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/movetorecobulk`, domainIds);
}

export const moveToReasearch = (domainIds) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/movetoresearchbulk`, domainIds);
}

export const moveToRecoEsAnalyst = (domainIds) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/movetorecobulk/es-analyst`, domainIds);
}

export const moveToResearchEsAnalyst = (domainIds) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/movetoresearchbulk/es-analyst`, domainIds);
}

export const reviewLeadsAndSocials = async (data) => {
   return axiosInstance.post('/v1/acqu-shortlisted-domains/review-leads-socials', data);
 };

export const approveAprBulk = (updatedDomains, status) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/approval`, updatedDomains);
}

export const orderDomainsBulk = (updatedDomains) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/approve-and-place-orders`, updatedDomains);
}

export const zeroAndArchiveBulk = (updatedDomains) => {
   const domainsWithZeroApr = updatedDomains.map(domain => ({
      ...domain,
      apr: 0
   }));
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/approval`, domainsWithZeroApr);
}

export const sendBackBulk = (updatedDomains) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/unapproval`, updatedDomains);
}

export const recommendDomainsBulk = (updatedDomains) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/recommendation`, updatedDomains);
};

export const zeroAndArchiveRecoBulk = (updatedDomains) => {
   const domainsWithZeroReco = updatedDomains.map(domain => ({
      ...domain,
      reco: 0
   }));
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/recommendation`, domainsWithZeroReco);
};

export const sendBackToShortlistedBulk = (updatedDomains) => {
   const domainIds = updatedDomains.map(domain => domain.id);
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/sendbacktoshortlistedbulk`, domainIds);
};

export const assignCode = (updatedDomains) => {
   return axiosInstance.post("/v1/acqu-shortlisted-domains/assign-code", updatedDomains)
};

export const allocateToSocialUser = (payload) => {
   return axiosInstance.post("/v1/acqu-shortlisted-domains/allocate-to-social-user", payload)
};

export const bulkUpdateAcquShortlistedDomain = (updatedDomains) => {
   return axiosInstance.put(`/v1/acqu-shortlisted-domains/bulkupdate-acqushortlisteddomain`, updatedDomains);
};

// Email Center APIs
export const moveToEmailCenter = (domainIds) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/move-to-email-center`, domainIds);
}

export const allocateToEmailProcessor = (payload) => {
   return axiosInstance.post(`/v1/acqu-shortlisted-domains/allocate-to-email-processor`, payload);
}

export const getAllTopLeadEmailAllocs = () => {
   return axiosInstance.get("/v1/acqu-shortlisted-domains/top-lead-email-allocs");
}

export const setEmailAllocationsWork = (allocations) => {
   return axiosInstance.post("/v1/acqu-shortlisted-domains/setemailallocationswork", allocations);
}

export const getMyEmailAllocations = () => {
   return axiosInstance.get("/getAll-topLead-emailAllocs");
}

export const getEmailAllocationsWork = () => {
   return axiosInstance.get("/getemailallocationswork");
}


export const getEmailsBank = () => {
   return axiosInstance.get("/getemailsbank");
}

export const getEmailChildren = () => {
   return axiosInstance.get("/getemailchildren");
}

export const getEmailCollectedData = () => {
   return axiosInstance.get("/getemailcollecteddata");
}

export const getDecisionHubDataPaginated = (props) => {
  let endpoint;
  if (props.isNlDomain) {
    endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-apr';
  } else if (props.isZeroReco) {
    endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-zero-reco-for-apr';
  } else {
    endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-apr';
  }

  return axiosInstance.get(endpoint, { 
    params: { 
      sourceType: props.mode,
      tab: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }  
  });
}

export const getEsDecisionHubDataPaginated = (props) => {
   let endpoint;
   if (props.isNlDomain) {
     endpoint = '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-apr';
   } else if (props.isZeroReco) {
     endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-zero-reco-for-apr';
   } else {
     endpoint = '/v1/acqu-shortlisted-domains/shortlisted-domains-for-apr';
   }
 
   return axiosInstance.get(endpoint, { 
     params: { 
       sourceType: props.mode,
       tab: props.tab,
       page: props.page,
       size: props.size,
       sort: props.sort,
       filter: props.filter,
       search: props.search
     }  
   });
};

export const getRecoHubDataPaginated = (props) => {
  let endpoint = props.isNlDomain
    ? '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-reco'
    : '/v1/acqu-shortlisted-domains/shortlisted-domains-for-reco';

  return axiosInstance.get(endpoint, {
    params: {
      sourceType: props.mode,
      tab: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

export const getEsRecoHubDataPaginated = (props) => {
  let endpoint = props.isNlDomain
    ? '/v1/acqu-shortlisted-domains/shortlisted-nl-domains-for-reco'
    : '/v1/acqu-shortlisted-domains/shortlisted-domains-for-reco';

  return axiosInstance.get(endpoint, {
    params: {
      sourceType: props.mode,
      tab: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

export const getOrderHubDataPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/shortlisted-domains-for-order', {
    params: {
      sourceType: props.mode,
      tab: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

export const getEsOrderHubDataPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/shortlisted-domains-for-order', {
    params: {
      sourceType: props.mode,
      tab: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

export const getAcquArchiveDomainsPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/shortlisted-domains-for-aq-archive', {
    params: {
      sourceType: props.mode,
      processingStates: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

export const getAcquEsArchiveDomainsPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/shortlisted-domains-for-aq-archive', {
    params: {
      sourceType: props.mode,
      processingStates: props.tab,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search
    }
  });
};

// Add new paginated API endpoints
export const getAcquShortlistedDomainsPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/by-research-state', {
    params: {
      sourceType: 'ed',
      researchState: props.tab,
      showDomainWithLeadsOnly: props.showDomainWithLeadsOnly,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search,
      domainType: props.domainType,
      emailState: props.emailState
    }
  });
};

export const getEsShortlistedDomainsPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/by-research-state', {
    params: {
      sourceType: 'es',
      researchState: props.tab,
      showDomainWithLeadsOnly: props.showDomainWithLeadsOnly,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search,
      domainType: props.domainType,
      emailState: props.emailState
    }
  });
};

export const getEsAnalystShortlistedDomainsPaginated = (props) => {
  return axiosInstance.get('/v1/acqu-shortlisted-domains/by-research-state/es-analyst', {
    params: {
      sourceType: 'es',
      researchState: props.tab,
      showDomainWithLeadsOnly: props.showDomainWithLeadsOnly,
      page: props.page,
      size: props.size,
      sort: props.sort,
      filter: props.filter,
      search: props.search,
      domainType: props.domainType,
      emailState: props.emailState
    }
  });
};

export const getAllPortfolioDomains = (props) => {
   return axiosInstance.get("/getallportfoliodomains", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
         portfolio: props.portfolio
      }
   });
}

export const getOwnedActiveDomains = (props) => {
   return axiosInstance.get("/getowned/active", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
      }
   });
}

export const getOwnedExpiringDomains = (props) => {
   return axiosInstance.get("/getowned/expiring", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
      }
   });
}

export const getOwnedExpiredDomains = (props) => {
   return axiosInstance.get("/getowned/expired", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
      }
   });
}

export const getOwnedNewDomains = (props) => {
   return axiosInstance.get("/getowned/new", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
      }
   });
}

export const getOwnedDeletedDomains = (props) => {
   return axiosInstance.get("/getowned/deleted", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search,
      }
   });
}

export const getAllOwnedDomainsWithPagination = (props) => {
   return axiosInstance.get("/v2/getallowneddomains", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search
      }
   });
}

export const getAllAldDomainsWithPagination = (props) => {
   return axiosInstance.get("/v2/getallalddomains", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search
      }
   });
};

export const getAllLtdDomainsWithPagination = (props) => {
   return axiosInstance.get("/v2/getallltddomains", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search
      }
   });
}


export const getAllPDDomainsWithPagination = (props) => {
   return axiosInstance.get("/v2/getallpddomains", { 
      params: {
         page: props.page,
         size: props.size,
         sort: props.sort,
         filter: props.filter,
         search: props.search
      }
   });
}